import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';
import { PROMO_CODE_IS_NOT_VALID } from '../../app/constants/configuration';
import {
  addPaymentCard,
  fetchPaymentCards,
  fetchPaymentTransactions,
  getValidationSubscriptionsPromoCode,
  getValidationVideoPromoCode,
  mggBuySubscription,
  mggBuyVideo,
  mggUnsubscribe,
  mggUnsubscribeAndroid,
  removePaymentCard
} from '../../api/paymentsApi';

const initialState = {
  promoCodes: {
    promoCode: null,
    isValid: null,
    price: null,
    priceWithPromoCode: null,
    status: 'idle',
    error: null
  },
  purchase: {
    purchaseFormData: null,
    status: 'idle',
    error: null
  },
  selectedPaymentCard: null,
  paymentSubscriptionId: null,
  paymentTariffId: null,
  paymentVideoId: null,
  cards: {
    status: 'idle',
    list: []
  },
  transactions: {
    status: 'idle',
    limit: 0,
    offset: 0,
    total: 0,
    hasMore: false,
    error: null,
    list: []
  },
  addCardForm: null
};

// Promo codes validations
export const validatePromoCode = createAsyncThunk(
  'payments/validatePromoCode',
  async ({ subscriptionId, promoCode, videoId, tariffId }) => {
    if (subscriptionId) {
      // validate subscription promo code
      return await getValidationSubscriptionsPromoCode({
        product_id: subscriptionId,
        promocode: promoCode
      });
    } else {
      // validate video promo code
      return await getValidationVideoPromoCode({
        video_id: videoId,
        tariff_id: tariffId,
        promocode: promoCode
      });
    }
  }
);

// Billing
export const fetchUserPaymentCards = createAsyncThunk(
  'payments/fetchUserPaymentCards',
  async () => {
    return await fetchPaymentCards();
  }
);

export const addUserPaymentCard = createAsyncThunk('payments/addUserPaymentCard', async (body) => {
  return await addPaymentCard(body);
});

export const removeUserPaymentCard = createAsyncThunk(
  'payments/removeUserPaymentCard',
  async (body) => {
    return await removePaymentCard(body);
  }
);

export const fetchUserTransactions = createAsyncThunk(
  'payments/fetchUserTransactions',
  async ({ limit, offset }) => {
    return await fetchPaymentTransactions(limit, offset);
  }
);

export const buyVideo = createAsyncThunk('payments/buyVideo', async (body) => {
  return await mggBuyVideo(body);
});

export const buySubscription = createAsyncThunk('payments/buySubscription', async (body) => {
  return await mggBuySubscription(body);
});

export const removeSubscription = createAsyncThunk('payments/removeSubscription', async (body) => {
  return await mggUnsubscribe(body);
});

export const removeSubscriptionAndroid = createAsyncThunk(
  'payments/removeSubscriptionAndroid',
  async (body) => {
    return await mggUnsubscribeAndroid(body);
  }
);

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPromoCode: (state, action) => {
      state.promoCodes.promoCode = action.payload;
    },
    resetPromoCode: (state) => {
      state.promoCodes = initialState.promoCodes;
      state.paymentSubscriptionId = null;
      state.paymentTariffId = null;
      state.paymentVideoId = null;
    },
    setSelectedPaymentCard: (state, action) => {
      state.selectedPaymentCard = action.payload;
    },
    setPaymentSubscriptionId: (state, action) => {
      state.paymentSubscriptionId = action.payload;
    },
    setPaymentTariffId: (state, action) => {
      state.paymentTariffId = action.payload;
    },
    setPaymentVideoId: (state, action) => {
      state.paymentVideoId = action.payload;
    },
    setPaymentRedirectUrl: (state, action) => {
      state.paymentRedirectUrl = action.payload;
    },
    setInitialPurchaseInfo: (state) => {
      state.purchase = initialState.purchase;
    }
  },
  extraReducers: {
    [validatePromoCode.pending]: (state) => {
      state.promoCodes = {
        ...initialState.promoCodes,
        status: FETCH_STATUS_LOADING
      };
    },
    [validatePromoCode.fulfilled]: (state, action) => {
      state.promoCodes.status = FETCH_STATUS_SUCCESS;
      state.promoCodes.isValid = true;
      state.promoCodes.price = action.payload.data.price;
      state.promoCodes.priceWithPromoCode = action.payload.data.price_with_promocode;
    },
    [validatePromoCode.rejected]: (state, action) => {
      state.promoCodes.status = FETCH_STATUS_FAILED;
      state.promoCodes.error = action.error;

      if (action.error.message === PROMO_CODE_IS_NOT_VALID) {
        state.promoCodes.isValid = false;
      }
    },
    [buyVideo.pending]: (state) => {
      state.purchase.status = FETCH_STATUS_LOADING;
    },
    [buyVideo.fulfilled]: (state, action) => {
      state.purchase.status = FETCH_STATUS_SUCCESS;
      state.purchase.purchaseFormData = action.payload.data;
    },
    [buyVideo.rejected]: (state, action) => {
      state.purchase.status = FETCH_STATUS_FAILED;
      state.purchase.error = action.error;
    },
    [buySubscription.pending]: (state) => {
      state.purchase.status = FETCH_STATUS_LOADING;
    },
    [buySubscription.fulfilled]: (state, action) => {
      state.purchase.status = FETCH_STATUS_SUCCESS;
      state.purchase.purchaseFormData = action.payload.data;
    },
    [buySubscription.rejected]: (state, action) => {
      state.purchase.status = FETCH_STATUS_FAILED;
      state.purchase.error = action.error;
    },
    [fetchUserPaymentCards.pending]: (state) => {
      state.cards.status = FETCH_STATUS_LOADING;
    },
    [fetchUserPaymentCards.fulfilled]: (state, action) => {
      state.cards.status = FETCH_STATUS_SUCCESS;
      state.cards.list = action.payload.data;
      state.selectedPaymentCard =
        action.payload.data.length > 0
          ? action.payload.data[0].id
          : initialState.selectedPaymentCard;
    },
    [fetchUserPaymentCards.rejected]: (state) => {
      state.cards.status = FETCH_STATUS_FAILED;
    },
    [addUserPaymentCard.fulfilled]: (state, action) => {
      state.addCardForm = action.payload.data;
    },
    [removeUserPaymentCard.fulfilled]: (state, action) => {
      state.cards.list = state.cards.list.filter((item) => item.id !== action.meta.arg.card_id);
    },
    [fetchUserTransactions.pending]: (state) => {
      state.transactions.status = FETCH_STATUS_LOADING;
    },
    [fetchUserTransactions.fulfilled]: (state, action) => {
      state.transactions.status = FETCH_STATUS_SUCCESS;
      state.transactions.list = action.payload.data.transactions;
      state.transactions.total = action.payload.data.total;
      state.transactions.limit = action.payload.data.limit;
      state.transactions.offset = action.payload.data.offset;
      state.transactions.hasMore = action.payload.data.has_more;
    },
    [fetchUserTransactions.rejected]: (state, action) => {
      state.transactions.status = FETCH_STATUS_FAILED;
      state.transactions.error = action.error;
    }
  }
});

// Selectors:
// promo code information
export const getPromoCodeInfo = (state) => state.payments.promoCodes;

// purchase (video or subscription)
export const getPurchaseForm = (state) => state.payments.purchase.purchaseFormData;
export const getPurchaseInfoStatus = (state) => state.payments.purchase.status;
export const getPaymentSubscriptionId = (state) => state.payments.paymentSubscriptionId;
export const getPaymentTariffId = (state) => state.payments.paymentTariffId;
export const getPaymentVideoId = (state) => state.payments.paymentVideoId;
export const getPaymentRedirectUrl = (state) => state.payments.paymentRedirectUrl;

// cards
export const getPaymentCardsInfo = (state) => state.payments.cards;
export const getAddPaymentCardForm = (state) => state.payments.addCardForm;
export const getSelectedPaymentCard = (state) => state.payments.selectedPaymentCard;

// transactions
export const getTransactionsInfo = (state) => state.payments.transactions;

// Actions
export const {
  setPromoCode,
  setInitialPurchaseInfo,
  setSelectedPaymentCard,
  setPaymentSubscriptionId,
  setPaymentTariffId,
  setPaymentVideoId,
  resetPromoCode,
  setPaymentRedirectUrl
} = paymentsSlice.actions;

// Reducer
export default paymentsSlice.reducer;
