import React from 'react';
import './Loader.scss';

const Loader = () => {
  return (
    <div className="Loader">
      <span className="Loader__one" />
      <span className="Loader__two" />
      <span className="Loader__three" />
    </div>
  );
};

export default Loader;
