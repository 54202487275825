import React, { Suspense, useEffect } from 'react';
import { DESKTOP_SD_VIEWPORT_SIZE } from '../../constants/configuration';
import { useLocationChange, useToggle, useViewport } from '../../helpers/hooks';
import Burger from '../../components/common/Header/Burger';

// Redux
import { useSelector } from 'react-redux';
import { selectAuthInfo } from '../../../features/auth/authSlice';

// Lazy
const SideBar = React.lazy(() => import('../../components/common/Header/SideBar'));

const HeaderBurgerContainer = () => {
  const { isAuthorized } = useSelector(selectAuthInfo);
  const { viewportWidth } = useViewport();
  const [isOpened, setIsOpened, setIsClosed] = useToggle(false);

  useEffect(() => {
    document.body.style.overflow = isOpened ? 'hidden' : 'visible';
  }, [isOpened]);

  useLocationChange((location, prevLocation) => {
    if (location !== prevLocation && isOpened) {
      // console.log('changed from', prevLocation, 'to', location);
      setIsClosed();
    }
  });

  return (
    <>
      <Burger
        isVisible={viewportWidth < DESKTOP_SD_VIEWPORT_SIZE}
        isOpened={isOpened}
        handleToggleBurger={setIsOpened}
      />

      <Suspense fallback={null}>
        <SideBar isOpened={isOpened} isAuthorized={isAuthorized} />
      </Suspense>
    </>
  );
};

export default HeaderBurgerContainer;
