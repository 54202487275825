import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MIN_SEARCH_STRING_LENGTH } from '../../constants/configuration';

// Assets
import { ReactComponent as SearchIcon } from '../../assets/images/svg/search.svg';
import { useDebounce } from '../../helpers/hooks';

// Components
import SearchBar from '../../components/search/search-bar';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchVideosBySearcBar,
  getVideoSearchBarList
} from '../../../features/video-search/videoSearchSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { getConfigurationGenres } from '../../../features/video-configuration/videoConfigurationSlice';

const SearchBarContainer = () => {
  const [isSearchBarOpened, setIsSearchBarOpened] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleSearchBarValue = (value) => setInputValue(value);

  const handleCloseSearchBar = () => {
    setIsSearchBarOpened(false);
    setInputValue('');
  };
  const handleOpenSearchBar = () => setIsSearchBarOpened(true);

  const handleSubmitSearchBar = (value) => {
    if (value.length >= MIN_SEARCH_STRING_LENGTH) {
      handleCloseSearchBar();
      history.push({
        pathname: `/search/${value}`
      });
    }
  };

  const searchResults = useSelector(getVideoSearchBarList);
  const videoConfigGenres = useSelector(getConfigurationGenres);

  const debouncedSearchValue = useDebounce(inputValue, 750);

  useEffect(() => {
    if (searchResults.length > 0 && inputValue.length >= MIN_SEARCH_STRING_LENGTH) {
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(false);
    }
  }, [inputValue.length, searchResults.length]);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      try {
        isMounted &&
          debouncedSearchValue?.length >= MIN_SEARCH_STRING_LENGTH &&
          unwrapResult(await dispatch(fetchVideosBySearcBar({ text: debouncedSearchValue })));
      } catch (e) {
        console.log(e);
      }
    })();

    return () => (isMounted = false);
  }, [debouncedSearchValue, dispatch]);

  return (
    <>
      {isSearchBarOpened ? (
        <SearchBar
          searchBarValue={inputValue}
          handleSearchBarValue={handleSearchBarValue}
          handleSubmitSearchBar={handleSubmitSearchBar}
          handleCloseSearchBar={handleCloseSearchBar}
          searchResults={searchResults.slice(0, 10)}
          isDropdownVisible={isDropdownVisible}
          videoConfigGenres={videoConfigGenres}
        />
      ) : (
        <span onClick={handleOpenSearchBar}>
          <SearchIcon />
        </span>
      )}
    </>
  );
};

export default SearchBarContainer;
