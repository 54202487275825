import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { TOO_MANY_REQUESTS_ERROR } from '../../constants/configuration';
import { toast } from 'react-toastify';

// Components
import PortalContainer from '../PortalContainer';
import Modal from '../../components/common/Modal';

// Redux stuff
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { loginOauth2 } from '../../../features/auth/authSlice';

const AuthModalContainer = ({
  title,
  description,
  children,
  socials = false,
  modalClassName = ''
}) => {
  const { t } = useTranslation();

  const { GOOGLE_APP_ID, FB_APP_ID } = window['RUN_CONFIG'];

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleModalClose = useCallback(() => {
    const backgroundLocation = location.state ? location.state.background : '/';
    history.push(backgroundLocation);
  }, [history, location.state]);

  const escapeListener = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        handleModalClose();
      }
    },
    [handleModalClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', escapeListener, false);

    return () => {
      document.removeEventListener('keydown', escapeListener, false);
    };
  }, [escapeListener]);

  const handleSocialLoginSuccess = async (user) => {
    try {
      unwrapResult(await dispatch(loginOauth2(user)));
    } catch (error) {
      // handle 430 error
      if (error.message === TOO_MANY_REQUESTS_ERROR) {
        toast.error(t('notifications.MANY_REQUEST_ERROR'));
      }
      console.log({ error });
    }
    toast.success(t('notifications.AUTHORIZATION_SUCCESS'));
    history.push(location.state.background);
  };

  const handleSocialLoginFailure = (e) => {
    console.log('handleSocialLoginFailure', e);
  };

  return (
    <PortalContainer>
      <div className="ModalOverlay">
        <Modal
          modalClassName={modalClassName}
          title={title}
          socials={socials}
          fbAppId={FB_APP_ID}
          googleAppId={GOOGLE_APP_ID}
          description={description}
          onModalClose={handleModalClose}
          onSocialLoginSuccess={handleSocialLoginSuccess}
          onSocialLoginFailure={handleSocialLoginFailure}
        >
          {children}
        </Modal>
      </div>
    </PortalContainer>
  );
};

AuthModalContainer.propTypes = {
  modalClassName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  socials: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element).isRequired,
    PropTypes.element.isRequired
  ]).isRequired
};

export default AuthModalContainer;
