import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Assets
import './RecommendedList.scss';
import CardsItem, { CardsItemSkeleton } from '../../cards/cards-item';

const RecommendedList = ({ items, recommendedListHeading, updateVideoList }) => {
  const recommendedListMarkup =
    items?.length > 0
      ? items.map((item) => (
          <CardsItem
            updateVideoList={updateVideoList}
            key={item.id}
            data={item}
            withDescription={false}
          />
        ))
      : Array.from({ length: 12 }).map((item, index) => <CardsItemSkeleton key={index} />);

  return (
    <section className="RecommendedList">
      <div className="Container">
        <h2 className="RecommendedList__heading">{recommendedListHeading}</h2>
        <div className="RecommendedList__wrapper">{recommendedListMarkup}</div>
      </div>
    </section>
  );
};

RecommendedList.propTypes = {
  items: PropTypes.array.isRequired,
  recommendedListHeading: PropTypes.string,
  updateVideoList: PropTypes.func
};

export default memo(RecommendedList);
