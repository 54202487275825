import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';

// Components
import MainBanner from '../../components/common/MainBanner';
// import BottomBanner from '../../components/common/BottomBanner';
import CardsSlider from '../../components/cards/cards-slider';
import ChannelsSlider from '../../components/channels/channels-slider';
import UserRecommendedVideosContainer from '../../containers/UserRecommendedVideosContainer';
import LoginForm from '../../components/forms/LoginForm';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  fetchNewestCartoons,
  fetchNewestMovies,
  fetchNewestSeries,
  fetchPremieresCollection,
  getNewestCartoons,
  getNewestMovies,
  getNewestSeries,
  getNewestShow,
  getPremieresCollection,
  fetchNewestShows
} from '../../../features/video-collections/videoCollectionsSlice';
import {
  fetchMoviesChannelsListPopular,
  getChannelsListPopular
} from '../../../features/channels-collections/channelsCollectionsSlice';
import { getAccessToken } from '../../../features/auth/authSlice';
import { buildSearchParams } from '../../helpers/utils';

const Home = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { popupAuthorization } = useParams();
  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    track('Main Page Viewed');
  }, []);

  useEffect(() => {
    (async () => {
      try {
        unwrapResult(dispatch(fetchPremieresCollection()));
        unwrapResult(dispatch(fetchNewestMovies()));
        unwrapResult(dispatch(fetchNewestSeries()));
        unwrapResult(dispatch(fetchNewestShows()));
        unwrapResult(dispatch(fetchNewestCartoons()));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [dispatch, i18n.language]);

  useEffect(() => {
    (async () => {
      try {
        unwrapResult(dispatch(fetchMoviesChannelsListPopular()));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [dispatch, i18n.language, accessToken]);

  const premieres = useSelector(getPremieresCollection);
  const newestMovies = useSelector(getNewestMovies);
  const newestSeries = useSelector(getNewestSeries);
  const newestShow = useSelector(getNewestShow);
  const newestCartoons = useSelector(getNewestCartoons);
  const channelsListPopular = useSelector(getChannelsListPopular);
  const showPopupAuthorization = () => {
    switch (popupAuthorization) {
      case 'login':
        return <LoginForm />;
    }
  };

  const updatePremieres = async () => {
    return await unwrapResult(await dispatch(fetchPremieresCollection()));
  };

  const updateNewestMovies = async () => {
    return await unwrapResult(await dispatch(fetchNewestMovies()));
  };

  const updateNewestSeries = async () => {
    return await unwrapResult(await dispatch(fetchNewestSeries()));
  };
  const updateNewestShows = async () => {
    return await unwrapResult(await dispatch(fetchNewestShows()));
  };

  const updateNewestCartoons = async () => {
    return await unwrapResult(await dispatch(fetchNewestCartoons()));
  };

  const updateСhannelsListPopular = async () => {
    return await unwrapResult(await dispatch(fetchMoviesChannelsListPopular()));
  };

  return (
    <>
      {popupAuthorization && showPopupAuthorization()}
      <MainBanner />
      <ChannelsSlider
        updateFavoriteChannels={updateСhannelsListPopular}
        channels={channelsListPopular}
        heading={t('titles.channels')}
      />
      <CardsSlider
        heading={t('homePage.titles.premieres')}
        headingHref="/premieres"
        isHeadingLink={true}
        items={premieres}
        updateVideoList={updatePremieres}
      />
      <CardsSlider
        heading={t('homePage.titles.movies')}
        headingHref={`/newest-movies/?${buildSearchParams(16, 'year', null, null, null, null, 1)}`}
        isHeadingLink={true}
        items={newestMovies}
        updateVideoList={updateNewestMovies}
      />
      <CardsSlider
        heading={t('homePage.titles.cartoons')}
        headingHref={`/newest-cartoons/?${buildSearchParams(6, 'year', null, null, null, null, 1)}`}
        isHeadingLink={true}
        items={newestCartoons}
        updateVideoList={updateNewestCartoons}
      />
      <CardsSlider
        heading={t('homePage.titles.series')}
        headingHref={`/newest-series/?${buildSearchParams(4, 'year', null, null, null, null, 1)}`}
        isHeadingLink={true}
        items={newestSeries}
        updateVideoList={updateNewestSeries}
      />
      <CardsSlider
        heading={t('homePage.titles.show')}
        headingHref={`/newest-show/?${buildSearchParams(9, 'year', null, null, null, null, 1)}`}
        isHeadingLink={true}
        items={newestShow}
        updateVideoList={updateNewestShows}
      />

      <UserRecommendedVideosContainer
        recommendedListHeading={t('homePage.titles.recommended')}
        isSlider={true}
        limit={20}
      />
      {/* <BottomBanner /> */}
    </>
  );
};

export default Home;
