import React, { Suspense, lazy } from 'react';
import {
  // Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom';

// Components
import Loader from '../components/common/Loader';
import AppLayout from '../layouts/AppLayout/AppLayout';
import AuthorizedRoute from './AuthorizedRoute';
// import NotFound from '../views/NotFound';

// Redux stuff
import { useSelector } from 'react-redux';
import { selectAuthInfo } from '../../features/auth/authSlice';

// Pages
import Home from '../pages/Home';
const Success = lazy(() => import('../views/Success'));
const NotFound = lazy(() => import('../views/NotFound'));
const Channel = lazy(() => import('../pages/Channel'));
const Video = lazy(() => import('../pages/Video'));

// Lazy routes
const Profile = lazy(() => import('../pages/Profile'));
const Devices = lazy(() => import('../pages/Devices'));
const Cards = lazy(() => import('../pages/Cards'));
const ParentControl = lazy(() => import('../pages/ParentControl'));
const ChangePassword = lazy(() => import('../pages/ChangePassword'));
const Subscriptions = lazy(() => import('../pages/Subscriptions'));

// video collections pages
const Movies = lazy(() => import('../pages/Movies'));
const NewestMovies = lazy(() => import('../pages/Movies/movies-categories/NewestMovies'));
const PopularMovies = lazy(() => import('../pages/Movies/movies-categories/PopularMovies'));
const LatestMovies = lazy(() => import('../pages/Movies/movies-categories/LatestMovies'));
const Cartoons = lazy(() => import('../pages/Cartoons'));
const NewestCartoons = lazy(() => import('../pages/Cartoons/cartoons-categories/NewestCartoons'));
const PopularCartoons = lazy(() => import('../pages/Cartoons/cartoons-categories/PopularCartoons'));
const LatestCartoons = lazy(() => import('../pages/Cartoons/cartoons-categories/LatestCartoons'));
const Series = lazy(() => import('../pages/Series'));
const NewestSeries = lazy(() => import('../pages/Series/series-categories/NewestSeries'));
const PopularSeries = lazy(() => import('../pages/Series/series-categories/PopularSeries'));
const Show = lazy(() => import('../pages/Show'));
const NewestShow = lazy(() => import('../pages/Show/show-categories/NewestShow'));
const PopularShow = lazy(() => import('../pages/Show/show-categories/PopularShow'));
const LatestShow = lazy(() => import('../pages/Show/show-categories/LatestShow'));
const LatestSeries = lazy(() => import('../pages/Series/series-categories/LatestSeries'));
const Search = lazy(() => import('../pages/Search'));
const Premieres = lazy(() => import('../pages/Premieres'));
const DeepLinks = lazy(() => import('../pages/DeepLinks'));

// payments pages
const PaymentContent = lazy(() => import('../pages/PaymentContent'));
const PaymentSubscription = lazy(() => import('../pages/PaymentSubscription'));
const PaymentFormPage = lazy(() => import('../pages/PaymentFormPage'));
const AddPaymentCardPage = lazy(() => import('../pages/AddPaymentCardPage'));
const Channels = lazy(() => import('../pages/Channels'));
// const Subscribe = lazy(() => import('../pages/Subscribe'));
const Favorites = lazy(() => import('../pages/Favorites'));

// Auth modal forms
const LoginForm = lazy(() => import('../components/forms/LoginForm'));
const PasswordForm = lazy(() => import('../components/forms/PasswordForm'));
// const RegisterForm = lazy(() => import('../components/forms/RegisterForm'));
const RegisterConfirmForm = lazy(() => import('../components/forms/RegisterConfirmForm'));
const SmsEnterForm = lazy(() => import('../components/forms/SmsEnterForm'));
const PasswordResendForm = lazy(() => import('../components/forms/PasswordResendForm'));
const PasswordResetForm = lazy(() => import('../components/forms/PasswordResetForm'));
const PasswordConfirmForm = lazy(() => import('../components/forms/PasswordConfirmForm'));
const Privacy = lazy(() => import('../pages/Privacy'));

const Routes = () => {
  const { isAuthorized } = useSelector(selectAuthInfo);
  const location = useLocation();
  /* The `background` state is the location that we were at when login/reg clicked. */
  let background = location.state?.background;
  return (
    // for success payment page without common layout (header + footer)
    <>
      {location.pathname === '/privacy/ru' || location.pathname === '/privacy/ua' ? (
        <Route path="/privacy/:lang" component={Privacy} />
      ) : location.pathname === '/payment/success' ? (
        <Route exact path="/payment/success" component={Success} />
      ) : (
        <>
          <AppLayout>
            <Suspense fallback={<Loader />}>
              <Switch location={background || location}>
                <Route exact path="/" component={Home} />
                <Route path="/search/:text" component={Search} />
                <AuthorizedRoute path="/profile" component={Profile} />
                <AuthorizedRoute path="/devices" component={Devices} />
                <AuthorizedRoute path="/my-cards" component={Cards} />
                <AuthorizedRoute path="/parent-control" component={ParentControl} />
                <AuthorizedRoute path="/change-password" component={ChangePassword} />
                <AuthorizedRoute path="/subscriptions" component={Subscriptions} />
                {/* <Route path="/subscribe" component={Subscribe} /> */}
                <AuthorizedRoute exact path="/favorites" component={Favorites} />
                <Route exact path="/premieres" component={Premieres} />
                <Route exact path="/app/link" component={DeepLinks} />
                <Route
                  exact
                  path="/payment/content/:videoId/:tariffId"
                  component={PaymentContent}
                />
                <Route
                  exact
                  path="/payment/subscription/:subscriptionId"
                  component={PaymentSubscription}
                />
                <AuthorizedRoute
                  exact
                  path="/invoice/content/:videoId/:tariffId"
                  component={PaymentFormPage}
                />
                <AuthorizedRoute
                  exact
                  path="/payment/subscriptions/:id/:subscriptionId"
                  component={PaymentFormPage}
                />
                <AuthorizedRoute exact path="/payment/add" component={AddPaymentCardPage} />
                <Route exact path="/videos/:slug" component={Video} />
                <Route exact path="/tv/:slug" component={Video} />
                <Route exact path="/movies/" component={Movies} />
                <Route exact path="/movies/:slug" component={Video} />
                <Route exact path="/newest-movies" component={NewestMovies} />
                <Route exact path="/popular-movies" component={PopularMovies} />
                <Route exact path="/latest-movies" component={LatestMovies} />
                <Route exact path="/cartoons/" component={Cartoons} />
                <Route exact path="/cartoons/:slug" component={Video} />
                <Route exact path="/newest-cartoons" component={NewestCartoons} />
                <Route exact path="/popular-cartoons" component={PopularCartoons} />
                <Route exact path="/latest-cartoons" component={LatestCartoons} />
                <Route exact path="/series/" component={Series} />
                <Route exact path="/series/:slug" component={Video} />
                <Route exact path="/newest-series" component={NewestSeries} />
                <Route exact path="/popular-series" component={PopularSeries} />
                <Route exact path="/show/" component={Show} />
                <Route exact path="/show/:slug" component={Video} />
                <Route exact path="/newest-show" component={NewestShow} />
                <Route exact path="/popular-show" component={PopularShow} />
                <Route exact path="/latest-show" component={LatestShow} />
                <Route exact path="/latest-series" component={LatestSeries} />
                <Route exact path="/tv-channels" component={Channels} />
                <Route exact path="/channel/:id/:genre" component={Channel} />
                {!background && !isAuthorized && (
                  <Route path={`/auth/:popupAuthorization`} component={Home} />
                )}
                <Route path="*" component={NotFound} />
              </Switch>
            </Suspense>
          </AppLayout>
        </>
      )}

      {background && !isAuthorized && (
        <Route
          path="/auth"
          render={({ match }) => (
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route path={`${match.path}/login`} component={LoginForm} />
                <Route path={`${match.path}/password`} component={PasswordForm} />
                {/* <Route path={`${match.path}/register`} component={RegisterForm} /> */}
                <Route path={`${match.path}/confirm`} component={RegisterConfirmForm} />
                <Route path={`${match.path}/sms-confirm`} component={SmsEnterForm} />
                <Route path={`${match.path}/password-resend`} component={PasswordResendForm} />
                <Route path={`${match.path}/password-reset`} component={PasswordResetForm} />
                <Route path={`${match.path}/password-confirm`} component={PasswordConfirmForm} />
              </Switch>
            </Suspense>
          )}
        />
      )}
    </>
  );
};

export default Routes;
