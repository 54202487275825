import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBanners } from '../../api/banner';
import { getFromLocalStorage, setLocalStorage } from '../../app/helpers/utils';
import { BANNERS_DATA } from '../../app/constants/configuration';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  banners: {
    data: getFromLocalStorage(BANNERS_DATA),
    status: 'idle',
    error: null
  }
};

export const fetchBanners = createAsyncThunk('banners/fetchBanners', async () => {
  return await getBanners();
});

const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    updateBanners(state) {
      state.banners.data = getFromLocalStorage(BANNERS_DATA);
    }
  },
  extraReducers: {
    [fetchBanners.pending]: (state) => {
      state.banners.status = FETCH_STATUS_LOADING;
    },
    [fetchBanners.fulfilled]: (state, action) => {
      state.banners.status = FETCH_STATUS_SUCCESS;
      if (action.payload.data) {
        setLocalStorage(BANNERS_DATA, action.payload.data);
      }
      if (!state.banners.data) {
        state.banners.data = action.payload.data;
      }
    },
    [fetchBanners.rejected]: (state, action) => {
      state.banners.status = FETCH_STATUS_FAILED;
      state.banners.error = action.payload;
    }
  }
});

// Selectors
export const getBannersData = (state) => state.banners;

export const { updateBanners } = bannersSlice.actions;

export default bannersSlice.reducer;
