import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import bb_lg_svg from '../../../assets/images/main-banner/bb_lg.svg';
import bw_lg_svg from '../../../assets/images/main-banner/bw_lg.svg';
import bb_sm_svg from '../../../assets/images/main-banner/bb_sm.svg';
import bw_sm_svg from '../../../assets/images/main-banner/bw_sm.svg';

const ImgWithFallbackMainBanner = ({ sm, md, lg, alt, link, bg }) => {
  return (
    <div className="MainBanner__item">
      <Link to={link}>
        <picture className="MainBanner__item-logo">
          <source
            media="(min-width: 768px)"
            srcSet={bg === 'white' ? bw_lg_svg : bb_lg_svg}
            alt="Бриз"
          />
          <source
            media="(max-width: 425px)"
            srcSet={bg === 'white' ? bw_sm_svg : bb_sm_svg}
            alt="Бриз"
          />
          <img src={bg === 'white' ? bw_lg_svg : bb_lg_svg} alt="Бриз" />
        </picture>
      </Link>

      <picture>
        <source media="(min-width: 768px)" srcSet={lg} alt={alt} />
        <source media="(max-width: 425px)" srcSet={sm} alt={sm} />
        <source media="(min-width: 425px) and (max-width: 768px)" srcSet={md} alt={alt} />
        <img src={lg} alt={alt} />
      </picture>
    </div>
  );
};

ImgWithFallbackMainBanner.propTypes = {
  src: PropTypes.string,
  fallback: PropTypes.string,
  alt: PropTypes.string,
  type: PropTypes.string,
  lg: PropTypes.string,
  md: PropTypes.string,
  sm: PropTypes.string,
  link: PropTypes.string,
  bg: PropTypes.string
};

export default ImgWithFallbackMainBanner;
