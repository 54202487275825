import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { mggFetchVideosBySearch } from '../../api/searchApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  search: {
    status: 'idle',
    hasMore: false,
    limit: 0,
    offset: 0,
    videoList: [],
    error: null
  },
  searchBar: {
    status: 'idle',
    hasMore: false,
    limit: 0,
    offset: 0,
    videoList: [],
    error: null
  }
};

export const fetchVideosBySearch = createAsyncThunk(
  'videoSearch/fetchVideosBySearch',
  async ({ text, categoryId, limit, offset }, thunkAPI) => {
    return await mggFetchVideosBySearch(text, categoryId, limit, offset, {
      signal: thunkAPI.signal
    });
  }
);

export const fetchVideosBySearcBar = createAsyncThunk(
  'videoSearch/fetchVideosBySearcBar',
  async ({ text, categoryId, limit, offset }) => {
    return await mggFetchVideosBySearch(text, categoryId, limit, offset);
  }
);

const videoSearchSlice = createSlice({
  name: 'videoSearch',
  initialState,
  reducers: {
    setInitialVideoList: (state) => {
      state.search.videoList = initialState.search.videoList;
    },
    updateVideosBySearch: (state, action) => {
      const updatedList = state.search.videoList.map((i) => {
        if (i.id === action.payload.id) {
          return action.payload;
        } else {
          return i;
        }
      });
      state.search.videoList = updatedList;
    }
  },
  extraReducers: {
    [fetchVideosBySearch.pending]: (state) => {
      state.search = { ...initialState.search, status: FETCH_STATUS_LOADING };
    },
    [fetchVideosBySearch.fulfilled]: (state, action) => {
      state.search.status = FETCH_STATUS_SUCCESS;
      state.search.videoList = action.payload.data.video_list;
      state.search.limit = action.payload.data.limit;
      state.search.offset = action.payload.data.offset;
      state.search.hasMore = action.payload.data.has_more;
    },
    [fetchVideosBySearch.rejected]: (state, action) => {
      state.search.status = FETCH_STATUS_FAILED;
      state.search.error = action.error;
    },
    [fetchVideosBySearcBar.pending]: (state) => {
      state.searchBar = { ...initialState.searchBar, status: FETCH_STATUS_LOADING };
    },
    [fetchVideosBySearcBar.fulfilled]: (state, action) => {
      state.searchBar.status = FETCH_STATUS_SUCCESS;
      state.searchBar.videoList = action.payload.data.video_list;
    },
    [fetchVideosBySearcBar.rejected]: (state, action) => {
      state.searchBar.status = FETCH_STATUS_FAILED;
      state.searchBar.error = action.error;
    }
  }
});

export const getVideoSearchList = (state) => state.videoSearch.search.videoList;
export const getVideoSearchStatus = (state) => state.videoSearch.search.status;
export const getVideoSearchError = (state) => state.videoSearch.search.error;
export const getVideoSearchHasMore = (state) => state.videoSearch.search.hasMore;
export const getVideoSearchOffset = (state) => state.videoSearch.search.offset;
export const getVideoSearchLimit = (state) => state.videoSearch.search.limit;

export const getVideoSearchBarList = (state) => state.videoSearch.searchBar.videoList;

export const { setInitialVideoList, updateVideosBySearch } = videoSearchSlice.actions;

export default videoSearchSlice.reducer;
