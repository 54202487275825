import React from 'react';
import NavBar from '../../components/common/Header/NavBar';
import { useViewport } from '../../helpers/hooks';
import { DESKTOP_SD_VIEWPORT_SIZE } from '../../constants/configuration';
import { useTranslation } from 'react-i18next';

const NavBarContainer = () => {
  const { t } = useTranslation();

  const { viewportWidth } = useViewport();

  return (
    <NavBar
      isVisible={viewportWidth >= DESKTOP_SD_VIEWPORT_SIZE}
      navBarRoutes={t('header.nav', { returnObjects: true })}
      navBarClassName="HeaderNav"
    />
  );
};

export default NavBarContainer;
