import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { getUserProfile, removeProfileData } from '../../features/user/userSlice';
import { HandleParentControlState } from '../helpers/handleParentControlState';
import { track } from '@amplitude/analytics-browser';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  setInitialFavoritesChannels,
  setInitialChannelsGrouped
} from '../../features/channels/channelsSlice';

export const useViewport = () => {
  const [viewportWidth, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { viewportWidth };
};

export const useLoaded = (timeout = 2500) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsLoaded(true);
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [timeout]);

  // Return the width so we can use it in our components
  return { isLoaded };
};

export const useToggle = (initialState) => {
  const [isToggled, setIsToggled] = React.useState(initialState);

  // put [setIsToggled] into the useCallback's dependencies array
  // this value never changes so the callback is not going to be ever re-created
  const toggle = React.useCallback(() => setIsToggled((state) => !state), [setIsToggled]);

  const toggleOff = React.useCallback(() => setIsToggled(false), []);

  const toggleOn = React.useCallback(() => setIsToggled(true), []);

  return [isToggled, toggle, toggleOff, toggleOn];
};

export const useShowMore = (initialState = false, ref) => {
  const [isShowMore, setIsShowMore] = useState(initialState);

  useEffect(() => {
    let offsetHeight = ref.current !== undefined ? ref.current.offsetHeight : 0;
    let scrollHeight = ref.current !== undefined ? ref.current.scrollHeight : 0;

    if (offsetHeight !== scrollHeight) {
      setIsShowMore(true);
    } else {
      setIsShowMore(false);
    }
  }, [ref]);

  return [isShowMore];
};

export const useLoginForm = (initialState) => {
  const [openedLoginForm, setOpenedLoginForm] = useState(initialState);

  const history = useHistory();
  const openLoginForm = React.useCallback(
    (newlocation) => {
      setOpenedLoginForm(true);
      return history.push({
        pathname: '/auth/login',
        state: {
          background: newlocation
        }
      });
    },
    [history]
  );

  return { openedLoginForm, setOpenedLoginForm, openLoginForm };
};

export const usePrevious = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useLocationChange = (action) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    action(location, prevLocation);
  }, [action, location, prevLocation]);
};

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [delay, value]
  );

  return debouncedValue;
};

/**
 * Set now-time every @refreshTimeout seconds
 * @param refreshTimeout
 * dateNow: Number (milliseconds)
 */
export const useDateNow = (refreshTimeout = 10000) => {
  const [dateNow, setDateNow] = useState(Date.now());

  useEffect(() => {
    const timeout = setTimeout(() => setDateNow(Date.now()), refreshTimeout);
    return () => clearTimeout(timeout);
  }, [dateNow, refreshTimeout]);

  return { dateNow };
};

export const useLogout = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { name, groups, id, email, phone } = useSelector(getUserProfile);
  const dispatch = useDispatch();
  const logoutHandle = useCallback(async () => {
    await unwrapResult(await dispatch(logout()));
    toast.success(t('notifications.LOGOUT_SUCCESS'));
    track('Logged Out ', {
      UserId: id,
      UserLogin: `${email}, ${phone}`,
      UserName: name,
      AccountType: groups?.[0].name
    });
    dispatch(removeProfileData());
    dispatch(setInitialChannelsGrouped());
    dispatch(setInitialFavoritesChannels());
    history.push('/');
    HandleParentControlState.unsetCheckedParentControl();
  }, [dispatch, email, groups, history, id, name, phone, t]);

  return { logoutHandle };
};
