import {
  CARTOONS_CATEGORY_NUMBER,
  MOVIES_CATEGORY_NUMBER,
  RUSSIAN_LANGUAGE,
  SERIES_CATEGORY_NUMBER,
  SHOWS_CATEGORY_NUMBER,
  UKRAINIAN_LANGUAGE
} from '../constants/configuration';

export const getFieldsErr = (e) => {
  const errFields = JSON.parse(e.message);
  let errTexts = {};
  for (let key in errFields) {
    let field = { [key]: errFields[key][0] };
    errTexts = { ...errTexts, ...field };
  }
  return errTexts;
};

export const getNextSubscription = (items) => {
  let productId = null;
  let startedAt = null;
  items.forEach((item) => {
    if (item.payment_info) {
      productId = item.payment_info?.next_subscription?.product.id;
      startedAt = item.payment_info?.next_subscription?.starting_at;
    }
  });
  return { productId, startedAt };
};

export const toPathNameByVideoCategory = (categories) => {
  let pathName = 'videos';
  if (categories?.[0] === SERIES_CATEGORY_NUMBER) {
    pathName = 'series';
  } else if (categories?.[0] === CARTOONS_CATEGORY_NUMBER) {
    pathName = 'cartoons';
  } else if (categories?.[0] === MOVIES_CATEGORY_NUMBER) {
    pathName = 'movies';
  } else if (categories?.[0] === SHOWS_CATEGORY_NUMBER) {
    pathName = 'show';
  }

  return pathName;
};

export const checkWebpSupport = () => {
  return new Promise((res) => {
    const webP = new Image();
    webP.src =
      'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    webP.onload = webP.onerror = () => {
      res(webP.height === 2);
    };
  });
};

//  "date": "2020-08-28T11:41:17Z"
export const getFormattedDate = (date, divider = '/') => {
  if (!date) return;
  const formattedDate = date.slice(0, 10).split('-');
  return `${formattedDate[2]}${divider}${formattedDate[1]}${divider}${formattedDate[0]}`;
};

export const getFormattedLoginValue = (loginValue) => {
  if (loginValue.length === 10 && loginValue.charAt(0) === '0') {
    loginValue = `+38${loginValue}`;
  }

  if (loginValue.substring(0, 3) === '380') {
    loginValue = `+${loginValue}`;
  }

  return loginValue;
};

export const getMaskedLoginValue = (loginValue, loginType) => {
  if (loginType === 'email') {
    return loginValue
      .split('@')
      .map((str) => {
        return `${str[0]}${'*'.repeat(str.length - 2)}${str[str.length - 1]}`;
      })
      .join('@');
  } else {
    return `${loginValue.slice(0, -9)} ${loginValue.slice(-9, -7)} *** ** ${loginValue.slice(-2)}`;
  }
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const mapVideoGenres = (genres, configGenres) => {
  let mappedGenres = [];
  if (!genres?.length || !configGenres?.length) return mappedGenres;
  genres.forEach((genre) => {
    configGenres.filter((item) => {
      if (item.id === genre) mappedGenres.push(item);
      return null;
    });
  });

  return mappedGenres;
};

export const numWord = (value) => {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return 0;
  if (num > 1 && num < 5) return 2;
  if (num == 1) return 1;
  return 0;
};

export const getDurationTime = (duration) => {
  if (duration) {
    const hours = Math.trunc(duration / 60);
    const minutes = duration % 60;
    return { hours, minutes };
  }
  return {
    minutes: 0,
    hours: 0
  };
};

export const getUaVideoYear = (videoYearTitle = '') => {
  // 2000-е
  if (videoYearTitle.slice(-4) === '00-е') {
    return `${videoYearTitle.slice(0, -2)}-ні`;
  }

  // 1970-е, 1980-е...
  if (videoYearTitle.slice(-2) === '-е') {
    return `${videoYearTitle.slice(0, -2)}-ті`;
  }

  // 1960 и раньше
  if (videoYearTitle.slice(-2) === 'ше') {
    return `${videoYearTitle.slice(0, 4)}-ті і раніше`;
  }

  return videoYearTitle;
};

export const getMappedVideoGenre = (videoGenreTitle = '', lang = 'ru') => {
  const map = {
    ru: {
      bio: 'Биография',
      theater: 'Театр',
      filming: 'Экранизация',
      erotic: 'Эротика',
      history: 'Исторический',
      western: 'Вестерн',
      anime: 'Аниме',
      arthouse: 'Арт-хаус',
      comedy: 'Комедии',
      action: 'Боевики',
      detective: 'Детективы',
      melodrama: 'Мелодрамы',
      thriller: 'Триллеры',
      horror: 'Ужасы',
      adventures: 'Приключения',
      fiction: 'Фантастика',
      crime: 'Криминал',
      drama: 'Драмы',
      military: 'Военные',
      documentary: 'Документальные',
      foreign: 'Зарубежные',
      bollywood: 'Индийское кино',
      comics: 'Комиксы',
      short: 'Короткометражные',
      kids: 'Детские',
      'full-length-cartoons': 'Полнометражные',
      'cartoon-serial': 'Мультсериалы',
      sojuzmultfilm: 'Союзмультфильм',
      toddlers: 'Для самых маленьких',
      fantasy: 'Фэнтези',
      'sport-show': 'Спортивные',
      cooking: 'Кулинария',
      hunt: 'Охота и рыбалка',
      family: 'Семейные',
      health: 'О здоровье',
      cognitive: 'Познавательные',
      travel: 'Путешествия',
      humor: 'Юмористические',
      music: 'Музыкальные',
      'reality-shows': 'Реалити-шоу',
      contests: 'Конкурсы',
      boxing: 'Бокс',
      football: 'Футбол',
      games: 'Игры',
      moda: 'Мода',
      entertaining: 'Развлекательные',
      'popular-science': 'Научно популярные',
      msport: 'M-sport',
      blogger: 'Блоггер',
      standup: 'Стендап',
      interview: 'Интервью',
      education: 'Образование',
      musical: 'Мюзиклы',
      historical: 'Исторические',
      adult: 'Для взрослых',
      animation: 'Анимация',
      'film-3d': 'Фильмы в 3D',
      '18+': '18+',
      sport: 'Спорт'
    },
    ua: {
      bio: 'Біографія',
      theater: 'Театр',
      filming: 'Екранізація',
      erotic: 'Еротика',
      history: 'Історичний',
      western: 'Вестерн',
      anime: 'Аніме',
      arthouse: 'Арт-хаус',
      comedy: 'Комедії',
      action: 'Бойовики',
      detective: 'Детективи',
      melodrama: 'Мелодрами',
      thriller: 'Трилери',
      horror: 'Жахи',
      adventures: 'Пригодницькі',
      fiction: 'Фантастика',
      crime: 'Кримінал',
      drama: 'Драми',
      military: 'Військові',
      documentary: 'Документальні',
      foreign: 'Зарубіжні',
      bollywood: 'Індійске кіно',
      comics: 'Комікси',
      short: 'Короткометражні',
      kids: 'Дитячі',
      'full-length-cartoons': 'Повнометражні',
      'cartoon-serial': 'Мультсеріали',
      sojuzmultfilm: 'Союзмультфільм',
      toddlers: 'Для самих маленьких',
      fantasy: 'Фентезі',
      'sport-show': 'Спортивні',
      cooking: 'Кулінария',
      hunt: 'Мисливство та рибалка',
      family: 'Сімейні',
      health: "Про здоров'я",
      cognitive: 'Пізнавальні',
      travel: 'Подорожі',
      humor: 'Гумористичні',
      music: 'Музикальні',
      'reality-shows': 'Реаліті-шоу',
      contests: 'Конкурси',
      boxing: 'Бокс',
      football: 'Футбол',
      games: 'Ігри',
      moda: 'Мода',
      entertaining: 'Розважальні',
      'popular-science': 'Науково популярні',
      msport: 'M-sport',
      blogger: 'Блогер',
      standup: 'Стендап',
      interview: 'Інтервʼю',
      education: 'Освіта',
      musical: 'Мюзикли',
      historical: 'Історичні',
      adult: 'Для дорослих',
      animation: 'Анімація',
      'film-3d': 'Фільми в 3D',
      '18+': '18+',
      sport: 'Спорт'
    }
  };

  for (let key in map[lang]) {
    if (videoGenreTitle.toUpperCase() === key.toUpperCase()) {
      return map[lang][key];
    }
  }

  return videoGenreTitle;
};

export const getMainVideoGenre = (genres, configGenres, lang = 'ru') => {
  const mappedGenres = mapVideoGenres(genres, configGenres);
  return getMappedVideoGenre(mappedGenres[0]?.path, lang);
};

export const getSubscriptionName = (name, lang = 'ru') => {
  const map = {
    ru: ['Базовый', 'Оптимальный', 'Премиум'],
    ua: ['Базовий', 'Оптимальний', 'Преміум']
  };

  for (const item of map[lang]) {
    if (item.includes(name.substring(0, 3))) {
      return item;
    }
  }

  return name;
};

export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export const getCurrentLanguage = (lang) => {
  return lang === UKRAINIAN_LANGUAGE ? 'uk' : RUSSIAN_LANGUAGE;
};

export const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
};

export const getFormateDate = (date) => {
  var dd = date.getDate();
  if (dd < 10) dd = '0' + dd;
  var mm = date.getMonth() + 1;
  if (mm < 10) mm = '0' + mm;
  var yy = date.getFullYear();
  //if (yy < 10) yy = '0' + yy;
  return dd + '.' + mm + '.' + yy;
};

export const getFormateTime = (date) => {
  var hh = date.getHours();
  if (hh < 10) hh = '0' + hh;
  var mm = date.getMinutes() + 1;
  if (mm < 10) mm = '0' + mm;
  return hh + ':' + mm;
};

export const defineCurrentTrackFromPlyr = (subtitles) => {
  const settingsFromlLocalStorage = JSON.parse(localStorage.getItem('plyr'));
  if (!settingsFromlLocalStorage?.captions) {
    return { currentTrack: -1, lang_original: '' };
  }
  if (settingsFromlLocalStorage?.language) {
    const currentIndex = subtitles.findIndex((i) => i.lang === settingsFromlLocalStorage?.language);
    return currentIndex === -1
      ? { currentTrack: -1, lang_original: '' }
      : { currentTrack: currentIndex, lang_original: subtitles[currentIndex].lang_original };
  }
  if (
    settingsFromlLocalStorage?.captions &&
    !settingsFromlLocalStorage?.language &&
    subtitles.length
  ) {
    return { currentTrack: 0, lang_original: subtitles[0].lang_original };
  }
};

export const throttle = (fn, delay) => {
  let lastCalled = 0;
  return (...args) => {
    let now = new Date().getTime();
    if (now - lastCalled < delay) {
      return;
    }
    lastCalled = now;
    return fn(...args);
  };
};

export const getCookie = (name) => {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`)
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, age) => {
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}; path=/; max-age=${age};`;
};
export const buildSearchParams = (
  categoryId,
  sort,
  yearMin,
  yearMax,
  genreId,
  bySubscriptionId,
  page
) => {
  const searchParamsString = `categoryId=${categoryId ? categoryId : ''}&sort=${
    sort ? sort : ''
  }&yearMin=${yearMin ? yearMin : ''}&yearMax=${yearMax ? yearMax : ''}&genreId=${
    genreId ? genreId : ''
  }&bySubscriptionId=${bySubscriptionId ? bySubscriptionId : ''}&page=${page}`;
  return new URLSearchParams(searchParamsString);
};

export const getPathFromCategoryId = (path, categoryId) => {
  if (Number(categoryId) === 16) {
    return `${path}movies`;
  }
  if (Number(categoryId) === 6) {
    return `${path}cartoons`;
  }
  if (Number(categoryId) === 4) {
    return `${path}series`;
  }
  if (Number(categoryId) === 9) {
    return `${path}show`;
  }
};

export const checkActivePath = (arr, path) => {
  let res = false;
  for (let i of arr) {
    if (path.includes(i)) {
      res = true;
      break;
    }
  }
  return res;
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const getUrlSearchParams = (params) => {
  const searchParams = new URLSearchParams(params) || {};
  const filterParams = {};
  for (const key of searchParams.keys()) {
    const value = searchParams.get(key);
    if (value) {
      filterParams[key] = searchParams.get(key);
    }
  }
  return filterParams;
};

export const getFromLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }
  return null;
};

export const setLocalStorage = (key, value) => {
  const data = JSON.stringify(value);
  localStorage.setItem(key, data);
};

export const isValidJson = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
};
