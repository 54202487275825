import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../components/common/Avatar';
import { toast } from 'react-toastify';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, uploadProfileImage } from '../../../features/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const AvatarContainer = ({ className, avatarTag, editable }) => {
  const { avatar, name } = useSelector(getUserProfile);
  const dispatch = useDispatch();

  const handleImageChange = async (event) => {
    try {
      const formData = new FormData();
      formData.append('avatar', event.target.files[0]);
      await unwrapResult(await dispatch(uploadProfileImage(formData)));
    } catch (error) {
      const err = error?.message && JSON.parse(error.message)?.avatar;
      if (Array.isArray(err)) {
        err.forEach((i) => toast.error(i));
      }
    }
  };

  const handleEditImage = () => {
    const imageInput = document.getElementById('imageInput');
    imageInput.click();
  };

  return (
    <Avatar
      userName={name}
      userAvatar={avatar}
      className={className}
      avatarTag={avatarTag}
      handleEditImage={handleEditImage}
      handleImageChange={handleImageChange}
      editable={editable}
    />
  );
};

AvatarContainer.propTypes = {
  className: PropTypes.string,
  avatarTag: PropTypes.string,
  editable: PropTypes.bool
};

export default AvatarContainer;
