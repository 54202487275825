import * as Yup from 'yup';
import 'yup-phone';
import i18n from 'i18next';

// Login file validation to check e-mail/phone number
Yup.addMethod(Yup.string, 'login', function (schemas, msg) {
  return this.test({
    name: 'login',
    message: msg,
    test: (value) => {
      if (Array.isArray(schemas) && schemas.length > 1) {
        const resee = schemas.map((schema) => schema.isValidSync(value));
        return resee.some((res) => res);
      } else {
        throw new TypeError('Schemas is not correct array schema');
      }
    },
    exclusive: false
  });
});

export const loginValidation = () => {
  return Yup.string()
    .login(
      [Yup.string().email(), Yup.string().phone('UA')],
      `${i18n.t('notifications.LOGIN_REQUIRED_VALIDATION')}`
    )
    .max(255, `${i18n.t('notifications.LOGIN_VALIDATION_MAX_LENGTH')}`)
    .required(`${i18n.t('notifications.LOGIN_REQUIRED')}`);
};

export const codeValidation = () => {
  return Yup.string()
    .matches(/^[^0-9]*$/, `${i18n.t('notifications.CODE_CANNOT_CONTAIN_DIGITS')}`)
    .min(5, `${i18n.t('notifications.CODE_REQUIRED')}`)
    .max(5, `${i18n.t('notifications.CODE_REQUIRED')}`)
    .required(`${i18n.t('notifications.CODE_REQUIRED')}`);
};

export const loginPasswordValidation = () => {
  return Yup.string()
    .min(6, `${i18n.t('notifications.PASSWORD_VALIDATION_MIN_LENGTH')}`)
    .max(16, `${i18n.t('notifications.PASSWORD_VALIDATION_MAX_LENGTH')}`)
    .required(`${i18n.t('notifications.PASSWORD_REQUIRED')}`);
};

export const pinValidation = () => {
  return Yup.string()
    .min(4, `${i18n.t('notifications.PIN_VALIDATION_MIN_LENGTH')}`)
    .required(`${i18n.t('notifications.PIN_REQUIRED')}`);
};

export const passwordValidation = () => {
  return Yup.string()
    .matches(/^\S*$/, `${i18n.t('notifications.PASSWORD_CONTAIN_SPACES')}`)
    .min(6, `${i18n.t('notifications.PASSWORD_VALIDATION_MIN_LENGTH')}`)
    .max(16, `${i18n.t('notifications.PASSWORD_VALIDATION_MAX_LENGTH')}`)
    .required(`${i18n.t('notifications.PASSWORD_REQUIRED')}`);
  // .matches(
  //   /^[a-zA-Z0-9()*_\-!#$%^&*,."'\][]+$/,
  //   `${i18n.t('notifications.PASSWORD_VALIDATION_ONLY_LATIN')}`
  // )
  // .matches(/[a-zA-Z]/, `${i18n.t('notifications.PASSWORD_VALIDATION_LETTER_REQUIRED')}`)
  // .matches(/(?=.*[0-9])/, `${i18n.t('notifications.PASSWORD_VALIDATION_DIGIT_REQUIRED')}`);
};

export const passwordConfirmValidation = () => {
  return Yup.string()
    .matches(/^\S*$/, `${i18n.t('notifications.PASSWORD_CONTAIN_SPACES')}`)
    .min(6, `${i18n.t('notifications.PASSWORD_VALIDATION_MIN_LENGTH')}`)
    .max(16, `${i18n.t('notifications.PASSWORD_VALIDATION_MAX_LENGTH')}`)
    .required(`${i18n.t('notifications.PASSWORD_CONFIRM_REQUIRED')}`);
};

// export const captchaValidation = Yup.string().nullable().required(CAPTCHA_ERROR);

export const commentValidation = () => {
  return Yup.string()
    .min(6, `${i18n.t('notifications.COMMENT_VALIDATION_MIN_LENGTH')}`)
    .max(1000, `${i18n.t('notifications.COMMENT_VALIDATION_MAX_LENGTH')}`);
};

export const usernameValidation = () => {
  Yup.string()
    .min(3, `${i18n.t('notifications.PROFILE_NAME_VALIDATION_MIN_LENGTH')}`)
    .max(30, `${i18n.t('notifications.PROFILE_NAME_VALIDATION_MAX_LENGTH')}`)
    .required(`${i18n.t('notifications.PROFILE_NAME_VALIDATION_REQUIRED')}`);
};

export const emailValidation = () => {
  return Yup.string().email(`${i18n.t('notifications.EMAIL_NOT_VALID')}`);
};

export const confirmCodeValidation = () => {
  return Yup.string()
    .matches(/[A-Za-z0-9]/, `${i18n.t('notifications.CONFIRM_CODE_VALIDATION')}`)
    .min(5, `${i18n.t('notifications.CONFIRM_CODE_VALIDATION')}`)
    .max(5, `${i18n.t('notifications.CONFIRM_CODE_VALIDATION')}`)
    .required(`${i18n.t('notifications.CONFIRM_CODE_REQUIRED')}`);
};

export const promoCodeValidation = () => {
  return Yup.string()
    .min(6, `${i18n.t('notifications.PROMO_CODE_VALIDATION')}`)
    .max(6, `${i18n.t('notifications.PROMO_CODE_VALIDATION')}`);
};
