import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';

// Assets
import { ReactComponent as FacebookIcon } from '../../../assets/images/svg/soc-f.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/images/svg/soc-i.svg';
import { ReactComponent as AppStore } from '../../../assets/images/svg/appButton.svg';
import { ReactComponent as AppGoogle } from '../../../assets/images/svg/GoogleButton.svg';
import { ReactComponent as Telegramm } from '../../../assets/images/svg/soc-t.svg';
import { ReactComponent as AndroidTV } from '../../../assets/images/svg/droidTvbutton.svg';
import { ReactComponent as AppleTv } from '../../../assets/images/svg/appTvbutton.svg';
import { ReactComponent as Tizen } from '../../../assets/images/svg/samsung.svg';
import { ReactComponent as WebOs } from '../../../assets/images/svg/lg.svg';

const FooterNav = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const analyticsEvent = (app) => {
    track(' Market Clicked', { MarketTitle: app });
  };
  return (
    <div className="FooterNav">
      <div className="Container">
        <nav>
          <div className="FooterNav__wrap">
            <section>
              <h5 className="Footer__heading">{t('footer.titles.forUsers')}</h5>
              <div className="Footer__links-wrap">
                <div>
                  <ul>
                    {t('footer.navList1', { returnObjects: true }).map(({ title, path }) => (
                      <li key={title}>
                        <Link to={path}>{title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <ul>
                    {t('footer.navList2', { returnObjects: true }).map(({ title, path }) => (
                      <li key={title}>
                        <Link to={path}>{title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <h5 className="Footer__heading">{t('footer.titles.phones')}</h5>
              <div className="Footer__links-wrap">
                <div>
                  <ul>
                    <li>
                      <a href="tel:+38 (048) 797 25 25">+38 (048) 797 25 25</a>
                    </li>
                    <li>
                      <a href="tel:+38 (048) 729 11 22">+38 (048) 729 11 22</a>
                    </li>
                    <li>
                      <a href="tel:+38 (093) 797 25 25">+38 (093) 797 25 25</a>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>
                      <a href="tel:+38 (068) 797 25 25">+38 (068) 797 25 25</a>
                    </li>
                    <li>
                      <a href="tel:+38 (095) 797 25 25">+38 (095) 797 25 25</a>
                    </li>
                    <li>
                      <a href="mailto:supp@briz.tv">supp@briz.tv</a>
                    </li>
                  </ul>
                </div>
              </div>

              <section className="FooterNav__soc--desktop">
                <p className="Footer__heading">{t('footer.titles.socials')}</p>
                <ul>
                  <li className="FooterNav__soc-links">
                    <a href="https://www.facebook.com/brizodessa/" target="blank">
                      <FacebookIcon />
                    </a>
                    <a href="https://www.instagram.com/briz_official/" target="blank">
                      <InstagramIcon />
                    </a>
                    <a href="https://t.me/brizinfo" target="blank">
                      <Telegramm />
                    </a>
                  </li>
                </ul>
              </section>

              {/*<div>
              <h5 className="Footer__heading">{t('footer.titles.address')}</h5>
              <ul>
                <li>
                  <span>{t('footer.street')}</span>
                </li>
                <li>
                  <span>пн-сб: 9:00-19:00</span>
                </li>
                <li>
                  <span>вс: {t('footer.holiday')}</span>
                </li>
              </ul>
              <h5 className="Footer__heading">E-MAIL</h5>
              <ul>
                <li>
                  <a href="mailto:abonotdel@briz.ua">abonotdel@briz.ua</a>
                </li>
              </ul>
            </div>*/}
            </section>
          </div>
          <section className="FooterNav__soc--mobile">
            <p className="Footer__heading">{t('footer.titles.socials')}</p>
            <ul>
              <li className="FooterNav__soc-links">
                <a href="https://www.facebook.com/brizodessa/" target="blank">
                  <FacebookIcon />
                </a>
                <a href="https://www.instagram.com/briz_official/" target="blank">
                  <InstagramIcon />
                </a>
                <a href="https://t.me/brizinfo" target="blank">
                  <Telegramm />
                </a>
              </li>
            </ul>
          </section>
          <section className="FooterNav__apps">
            <h5 className="Footer__heading">{t('footer.titles.apps')}</h5>
            <ul>
              <li>
                <a
                  target="_blank"
                  onClick={() => analyticsEvent('apple')}
                  href="https://apps.apple.com/ua/app/briz-tv/id1539403361?!=ru"
                  rel="noreferrer"
                >
                  <AppStore />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  onClick={() => analyticsEvent('apple')}
                  href="https://apps.apple.com/ua/app/briz-tv/id1539403361?!=ru"
                  rel="noreferrer"
                >
                  <AppleTv />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => analyticsEvent('google')}
                  href={`https://play.google.com/store/apps/details?id=ua.briz.androidtv&hl=${
                    lang == 'ru' ? 'ru' : 'uk'
                  }`}
                >
                  <AppGoogle />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => analyticsEvent('google')}
                  href={`https://play.google.com/store/apps/details?id=ua.briz.androidtv&hl=${
                    lang == 'ru' ? 'ru' : 'uk'
                  }`}
                >
                  <AndroidTV />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  onClick={() => analyticsEvent('samsung')}
                  href={`https://www.briz.ua/${lang}/help/briz-tv-na-televizor-samsung`}
                  rel="noreferrer"
                >
                  <Tizen />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  onClick={() => analyticsEvent('lg')}
                  href="https://ua.lgappstv.com/main/tvapp/detail?appId=1130160&catCode1=003&moreYn=N&cateYn=Y&orderType=0&headerName=%D0%A0%D0%BE%D0%B7%D0%B2%D0"
                  rel="noreferrer"
                >
                  <WebOs />
                </a>
              </li>
            </ul>
          </section>
        </nav>
      </div>
    </div>
  );
};

export default React.memo(FooterNav);
