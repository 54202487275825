import { client } from './api-client';

export const mggFetchVideosBySearch = (text, category_id, limit, offset, signal) => {
  const _category_id = category_id ? `&category_id=${category_id}` : '';
  const _limit = limit ? `&limit=${limit}` : '';
  const _offset = offset ? `&offset=${offset}` : '';
  return client.get(`mgg/search?text=${text}${_category_id}${_limit}${_offset}`, signal);
};

export const fetchChannelsBySearch = (text, genre_id, limit, offset, signal) => {
  const _category_id = genre_id ? `&category_id=${genre_id}` : '';
  const _limit = limit ? `&limit=${limit}` : '';
  const _offset = offset ? `&offset=${offset}` : '';
  return client.get(
    `tv/search?text=${encodeURIComponent(text)}${_category_id}${_limit}${_offset}`,
    signal
  );
};

export const fetchEpgBySearch = (text, genre_id, limit, offset, signal) => {
  const _category_id = genre_id ? `&category_id=${genre_id}` : '';
  const _limit = limit ? `&limit=${limit}` : '';
  const _offset = offset ? `&offset=${offset}` : '';
  return client.get(
    `tv/epg/search?text=${encodeURIComponent(text)}${_category_id}${_limit}${_offset}`,
    signal
  );
};
