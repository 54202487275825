import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { mggFetchVideoHistory } from '../../api/videoHistory';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  videoHistory: {
    status: 'idle',
    videoList: [],
    error: null
  }
};

export const fetchVideoHistory = createAsyncThunk('videoHistory/fetchVideoHistory', async () => {
  return await mggFetchVideoHistory();
});

const videoHistorySlice = createSlice({
  name: 'videoHistory',
  initialState,
  reducers: {
    setInitialVideoHistory: (state) => {
      state.videoHistory = initialState.videoHistory;
    },
    updateVideoHistory: (state, action) => {
      const updatedList = state.videoHistory.videoList.map((i) => {
        if (i.id === action.payload.id) {
          return action.payload;
        } else {
          return i;
        }
      });
      state.videoHistory.videoList = updatedList;
    }
  },
  extraReducers: {
    [fetchVideoHistory.pending]: (state) => {
      state.videoHistory.status = FETCH_STATUS_LOADING;
    },
    [fetchVideoHistory.fulfilled]: (state, action) => {
      state.videoHistory.status = FETCH_STATUS_SUCCESS;
      state.videoHistory.videoList = action.payload.data
        .map((i) => {
          const newItem = { ...i };
          newItem.date = Date.parse(i.updated_at);
          return newItem;
        })
        .sort((a, b) => b.date - a.date);
    },
    [fetchVideoHistory.rejected]: (state, action) => {
      state.videoHistory.status = FETCH_STATUS_FAILED;
      state.videoHistory.error = action.error;
    }
  }
});

export const getHistoryVideosList = (state) => state.history.videoHistory.videoList;
export const getHistoryVideosStatus = (state) => state.history.videoHistory.status;

export const { setInitialVideoHistory, updateVideoHistory } = videoHistorySlice.actions;

export default videoHistorySlice.reducer;
