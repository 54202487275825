import { client } from './api-client';

// User profile information
export const fetchGetProfile = () => client.get(`me`);
export const fetchUpdateProfile = (body) => client.patch(`me`, body);
export const fetchUploadAvatar = (body) => client.post(`avatar`, body);
export const userChangePassword = (body) => client.post('me', body);

// User video list collections (available for authorized user)
export const mggFetchUserRecommendedVideos = (limit) =>
  client.get(`mgg/user/recommended?limit=${limit}`);
export const mggFetchPurchasedVideos = (limit) => client.get(`mgg/user/tvod?limit=${limit}`);
