import React, { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import CardsItem, { CardsItemSkeleton } from '../cards-item';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import SliderButton from '../../sliders/SliderButton';

// Assets
import 'react-multi-carousel/lib/styles.css';
import './CardsSlider.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line react/prop-types
const ButtonGroup = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow }
  } = rest;
  return (
    <div className="CardsSlider__button-group">
      <SliderButton
        clickHandler={() => previous()}
        direction="prev"
        disabled={currentSlide === 0}
      />
      <SliderButton
        clickHandler={() => next()}
        direction="next"
        disabled={currentSlide >= totalItems - slidesToShow}
      />
    </div>
  );
};

const CardsSlider = ({
  sliderClassName,
  items,
  itemsLength = 6,
  heading,
  headingTag: HeadingTag = 'h2',
  isHeadingLink = false,
  headingHref = '/',
  cardComponent: CardComponent = CardsItem,
  skeletonComponent: SkeletonComponent,
  sliderCustomProps,
  updateVideoList
}) => {
  const { t } = useTranslation();
  let containerRef = useRef();

  const skeletonsMarkup = SkeletonComponent
    ? Array.from({ length: itemsLength }).map((item, index) => <SkeletonComponent key={index} />)
    : Array.from({ length: itemsLength }).map((item, index) => <CardsItemSkeleton key={index} />);

  const itemsMarkup =
    items?.length > 0
      ? items.map((item, index) => (
          <CardComponent updateVideoList={updateVideoList} key={item.id || index} data={item} />
        ))
      : skeletonsMarkup;

  const headingMarkup = heading && (
    <HeadingTag className="CardsSlider__heading">
      {isHeadingLink ? (
        <>
          <Link to={headingHref}>{heading}</Link>{' '}
          <Link to={headingHref} className="show-all-btn">
            {t('common.watchAll')}
          </Link>
        </>
      ) : (
        heading
      )}
    </HeadingTag>
  );

  useEffect(() => {
    const el = containerRef.current;
    let firstClientX, clientX;

    const preventTouch = (e) => {
      const minValue = 5;

      clientX = e.touches[0].clientX - firstClientX;
      if (Math.abs(clientX) > minValue) {
        e.preventDefault();
        e.returnValue = false;

        return false;
      }
    };

    const touchStart = (e) => {
      firstClientX = e.touches[0].clientX;
    };

    if (el) {
      el.addEventListener('touchstart', touchStart);
      el.addEventListener('touchmove', preventTouch, {
        passive: false
      });
    }

    return () => {
      if (el) {
        el.removeEventListener('touchstart', touchStart);
        el.removeEventListener('touchmove', preventTouch, {
          passive: false
        });
      }
    };
  }, []);

  return (
    <section className={classNames('CardsSlider', sliderClassName)}>
      <div className="CardsSlider__container Container">
        {headingMarkup}
        <div ref={containerRef} className="CardsSlider__content">
          <Carousel {...sliderProps} {...sliderCustomProps}>
            {itemsMarkup}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

const sliderProps = {
  swipeable: true,
  draggable: false,
  infinite: false,
  showDots: false,
  arrows: false,
  minimumTouchDrag: 10,
  transitionDuration: 1,
  renderButtonGroupOutside: true,
  customButtonGroup: <ButtonGroup />,
  partialVisible: true,
  keyBoardControl: false,
  containerClass: 'CardsSlider__slider',
  responsive: {
    desktop: {
      breakpoint: { max: 8000, min: 1280 },
      items: 6,
      slidesToSlide: 6
    },
    desktopMini2: {
      breakpoint: { max: 1279, min: 1025 },
      items: 5,
      slidesToSlide: 5,
      partialVisibilityGutter: 30
    },
    desktopMini1: {
      breakpoint: { max: 1024, min: 961 },
      items: 5,
      slidesToSlide: 5,
      partialVisibilityGutter: 5
    },
    desktopMini: {
      breakpoint: { max: 960, min: 801 },
      items: 4,
      slidesToSlide: 4,
      partialVisibilityGutter: 30
    },
    tabletHD: {
      breakpoint: { max: 800, min: 769 },
      items: 4,
      slidesToSlide: 4,
      partialVisibilityGutter: 20
    },
    tablet: {
      breakpoint: { max: 768, min: 601 },
      items: 4,
      slidesToSlide: 4,
      partialVisibilityGutter: 6
    },
    tablet1: {
      breakpoint: { max: 600, min: 501 },
      items: 3,
      slidesToSlide: 3,
      partialVisibilityGutter: 10
    },
    mobile3: {
      breakpoint: { max: 500, min: 394 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 30
    },
    mobile2: {
      breakpoint: { max: 393, min: 361 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 20
    },
    mobile: {
      breakpoint: { max: 360, min: 0 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 5
    }
  }
};

CardsSlider.propTypes = {
  sliderClassName: PropTypes.string,
  heading: PropTypes.string,
  headingTag: PropTypes.string,
  headingHref: PropTypes.string,
  isHeadingLink: PropTypes.bool,
  items: PropTypes.array,
  itemsLength: PropTypes.number,
  sliderCustomProps: PropTypes.object,
  cardComponent: PropTypes.elementType,
  skeletonComponent: PropTypes.elementType,
  updateVideoList: PropTypes.func
};

export default memo(CardsSlider);
