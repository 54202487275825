import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const TextInputComponent = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <TextField
      variant="filled"
      type="text"
      fullWidth
      {...props}
      {...field}
      error={touched[field.name] && Boolean(errors[field.name])}
    />
  );
};

TextInputComponent.propTypes = {
  field: PropTypes.shape({ name: PropTypes.string }),
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({})
  })
};

export default TextInputComponent;
