import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { mggFetchFavoriteVideos } from '../../api/videoFavoritesApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  favorites: {
    status: 'idle',
    hasMore: false,
    limit: 0,
    offset: 0,
    total: 0,
    videoList: [],
    error: null
  }
};

export const fetchFavoriteVideos = createAsyncThunk(
  'videoFavorites/fetchFavouriteVideos',
  async () => {
    return await mggFetchFavoriteVideos();
  }
);

const videoFavoritesSlice = createSlice({
  name: 'videoFavorites',
  initialState,
  reducers: {
    setInitialFavoriteVideos: (state) => {
      state.favorites = initialState.favorites;
    },
    updateFavoriteVideos: (state, action) => {
      const updatedPremiers = state.favorites.videoList.filter((i) => {
        return i.id !== action.payload.id;
      });
      state.favorites.videoList = updatedPremiers;
    }
  },
  extraReducers: {
    [fetchFavoriteVideos.pending]: (state) => {
      state.favorites.status = FETCH_STATUS_LOADING;
    },
    [fetchFavoriteVideos.fulfilled]: (state, action) => {
      state.favorites.status = FETCH_STATUS_SUCCESS;
      state.favorites.videoList = action.payload.data.video_list;
      state.favorites.total = action.payload.data.total;
      state.favorites.limit = action.payload.data.limit;
      state.favorites.offset = action.payload.data.offset;
      state.favorites.hasMore = action.payload.data.has_more;
    },
    [fetchFavoriteVideos.rejected]: (state, action) => {
      state.favorites.status = FETCH_STATUS_FAILED;
      state.favorites.error = action.error;
    }
  }
});

export const getFavoriteVideosList = (state) => state.videoFavorites.favorites.videoList;
export const getFavoriteVideosStatus = (state) => state.videoFavorites.favorites.status;
export const getFavoriteVideosTotal = (state) => state.videoFavorites.favorites.total;
export const getFavoriteVideosHasMore = (state) => state.videoFavorites.favorites.hasMore;
export const getFavoriteVideosOffset = (state) => state.videoFavorites.favorites.offset;
export const getFavoriteVideosLimit = (state) => state.videoFavorites.favorites.limit;

export const { setInitialFavoriteVideos, updateFavoriteVideos } = videoFavoritesSlice.actions;

export default videoFavoritesSlice.reducer;
