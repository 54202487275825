import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

export class UniqueIdGenerator {
  static instance;
  static value;
  constructor() {
    return (async () => {
      this.value = await getCurrentBrowserFingerPrint();
      return this;
    })();
  }

  getUniqueId() {
    return this.value;
  }

  static get() {
    if (!UniqueIdGenerator.instance) {
      UniqueIdGenerator.instance = new UniqueIdGenerator();
      return UniqueIdGenerator.instance;
    }
    return UniqueIdGenerator.instance;
  }
}
