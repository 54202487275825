import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchChannelsBySearch } from '../../api/searchApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  search: {
    status: 'idle',
    limit: 0,
    offset: 0,
    channelList: [],
    error: null
  }
};

export const fetchChannelBySearch = createAsyncThunk(
  'channelSearch/fetchChannelBySearch',
  async ({ text, categoryId = '', limit, offset }, thunkAPI) => {
    return await fetchChannelsBySearch(text, categoryId, limit, offset, {
      signal: thunkAPI.signal
    });
  }
);

const channelSearchSlice = createSlice({
  name: 'channelSearch',
  initialState,
  reducers: {
    setInitialChannelList: (state) => {
      state.search.channelList = initialState.search.channelList;
    },
    updateChannelSearchList: (state, action) => {
      const updatedChannels = state.search.channelList.map((i) => {
        if (i.id === action.payload.id) {
          return action.payload;
        }
        return i;
      });
      state.search.channelList = updatedChannels;
    }
  },
  extraReducers: {
    [fetchChannelBySearch.pending]: (state) => {
      state.search = { ...initialState.search, status: FETCH_STATUS_LOADING };
    },
    [fetchChannelBySearch.fulfilled]: (state, action) => {
      state.search.status = FETCH_STATUS_SUCCESS;
      state.search.channelList = action.payload.data.tv_channels_list;
      state.search.limit = action.payload.data.limit;
      state.search.offset = action.payload.data.offset;
    },
    [fetchChannelBySearch.rejected]: (state, action) => {
      state.search.status = FETCH_STATUS_FAILED;
      state.search.error = action.error;
    }
  }
});

export const getChannelSearchList = (state) => state.channelSearch.search.channelList;
export const getChannelSearchStatus = (state) => state.channelSearch.search.status;
export const getChannelSearchError = (state) => state.channelSearch.search.error;
export const getChannelSearchOffset = (state) => state.channelSearch.search.offset;
export const getChannelSearchLimit = (state) => state.channelSearch.search.limit;

export const { setInitialChannelListm, updateChannelSearchList } = channelSearchSlice.actions;

export default channelSearchSlice.reducer;
