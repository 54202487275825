import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toPathNameByVideoCategory } from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';

export const SearchBarListItem = ({
  id,
  categories,
  genres,
  slug,
  title,
  image,
  year,
  deliveryRules,
  handleClickListItem
}) => {
  const { t } = useTranslation();

  return (
    <Link
      to={`/${toPathNameByVideoCategory(categories)}/${id}-${slug}`}
      className="SearchBar__list-item"
      onClick={handleClickListItem}
    >
      <div className="SearchBar__img-wrap">
        <img src={image.small} alt={title} loading="lazy" />
      </div>
      <div className="SearchBar__list-item-content">
        <span>{title}</span>
        <span>
          {year}, {genres[0].title}
        </span>
        <span
          className={
            deliveryRules?.includes('tvod')
              ? 'SearchBar__list-item-content-purchase'
              : 'SearchBar__list-item-content-subscription'
          }
        >
          {deliveryRules?.includes('tvod') ? t('common.purchase') : t('common.subscription')}
        </span>
      </div>
    </Link>
  );
};

SearchBarListItem.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  slug: PropTypes.string,
  year: PropTypes.string,
  image: PropTypes.object,
  genres: PropTypes.array,
  categories: PropTypes.array,
  videoConfigGenres: PropTypes.array,
  deliveryRules: PropTypes.array,
  handleClickListItem: PropTypes.func
};
