import React from 'react';
import { useTranslation } from 'react-i18next';

const FooterCopyright = () => {
  const { t, i18n } = useTranslation();
  const year = new Date().getFullYear();
  return (
    <div className="FooterCopyright">
      <div className="Container">
        <p>
          <a href={`https://www.briz.ua${i18n.language === 'ua' ? '' : '/ru'}`}><span>{t('provider')}</span> BRIZ</a>
        </p>
        <p>{`Copyright © ${year} BRIZ. All rights reserved.`}</p>
      </div>
    </div>
  );
};

export default React.memo(FooterCopyright);
