import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  tvFetchChannelsByGenre,
  tvFetchChannelsList,
  tvFetchChannelsListPopular,
  tvFetchChannelCategories
} from '../../api/channelsApi';
import { MOVIES_GENRE_ID } from '../../app/constants/configuration';

const initialState = {
  moviesChannels: [],
  channelsList: [],
  channelsListPopular: [],
  channelsCategories: []
};

// Fetch movie channels
export const fetchMoviesChannels = createAsyncThunk(
  'channelsCollections/fetchMoviesChannels',
  async () => {
    return await tvFetchChannelsByGenre(MOVIES_GENRE_ID);
  }
);

// Fetch channels list
export const fetchMoviesChannelsList = createAsyncThunk(
  'channelsCollections/fetchMoviesChannelsList',
  async () => {
    return await tvFetchChannelsList();
  }
);
// Fetch channels list
export const fetchChannelCategories = createAsyncThunk(
  'channelsCategories/fetchChannelCategories',
  async () => {
    return await tvFetchChannelCategories();
  }
);
// Fetch channels list popular
export const fetchMoviesChannelsListPopular = createAsyncThunk(
  'channelsCollections/fetchMoviesChannelsListPopular',
  async () => {
    return await tvFetchChannelsListPopular();
  }
);

const channelsCollectionsSlice = createSlice({
  name: 'channelsCollections',
  initialState,
  extraReducers: {
    [fetchMoviesChannels.fulfilled]: (state, action) => {
      state.moviesChannels = action.payload.data;
    },
    [fetchMoviesChannelsList.fulfilled]: (state, action) => {
      state.channelsList = action.payload.data;
    },
    [fetchMoviesChannelsListPopular.fulfilled]: (state, action) => {
      state.channelsListPopular = action.payload.data;
    },
    [fetchChannelCategories.fulfilled]: (state, action) => {
      state.channelsCategories = action.payload.data;
    }
  }
});

// Selectors
export const getMoviesChannels = (state) => state.channelsCollections.moviesChannels;
export const getChannelsList = (state) => state.channelsCollections.channelsList;
export const getChannelsCategories = (state) => state.channelsCollections.channelsCategories;
export const getChannelsListPopular = (state) => state.channelsCollections.channelsListPopular;

// Reducer
export default channelsCollectionsSlice.reducer;
