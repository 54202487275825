import { client } from './api-client';

export const authLogin = (body) => client.post(`auth/login`, body);
export const authLogout = () => client.get(`auth/logout`);
export const authLoginCheck = (body) => client.post(`auth/login-check`, body);
export const authCodeLogin = ({code}) =>
  client.get(`auth/login/ws-code?code=${code}`);
export const authRegister = (body) => client.post(`auth/register`, body);
export const authRegisterConfirm = (body) => client.post(`auth/confirm`, body);
export const authRegisterConfirmResend = (body) => client.post(`auth/confirm-resend`, body);
export const authSmsCodeConfirm = (body) => client.post(`auth/login/sms-code`, body);
export const authSmsCodeConfirmResend = (body) => client.post(`auth/sms-code`, body);
export const authPasswordResend = (body) => client.post(`auth/password-resend`, body);
export const authPasswordReset = (body) => client.post(`auth/password`, body);
export const authPasswordCodeValidate = (body) => client.post(`auth/password-code`, body);
export const authLoginOauth2 = (body) => client.post(`auth/oauth2`, body);
export const authRefreshToken = (body) => client.post(`auth/refresh-token`, body);
