import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//assets
import './RegisterExternalUserContainer.scss';

// Components
import PortalContainer from '../PortalContainer';
import Modal from '../../components/common/Modal';
import { ReactComponent as Wheel } from '../../assets/images/svg/wheel.svg';

const RegisterExternalUserContainer = ({ onModalClose, modalClassName = '' }) => {
  const { t } = useTranslation();
  const handleModalClose = useCallback(() => {
    onModalClose();
  }, [onModalClose]);

  const escapeListener = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        handleModalClose();
      }
    },
    [handleModalClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', escapeListener, false);

    return () => {
      document.removeEventListener('keydown', escapeListener, false);
    };
  }, [escapeListener]);

  return (
    <PortalContainer>
      <div className="ModalOverlay">
        <Modal modalClassName={modalClassName} onModalClose={handleModalClose}>
          <Wheel />
          <p className="Modal_RegisterExternalUser__title">{t('onlyRegisterForBriz')}</p>
          <Link
            className="RegisterExternalUser__proceed"
            to="/"
            onClick={handleModalClose}
          >
            {t('goToMain')}
          </Link>
        </Modal>
      </div>
    </PortalContainer>
  );
};

RegisterExternalUserContainer.propTypes = {
  modalClassName: PropTypes.string,
  onModalClose: PropTypes.func
};

export default RegisterExternalUserContainer;
