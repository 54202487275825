import React, { useEffect } from 'react';
// import Carousel from 'react-multi-carousel';
import { Pagination, Navigation, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// Components
import ImgWithFallbackMainBanner from '../ImgWithFallbackMainBanner/';
// import { dataBannerSliders } from './dataBannerSliders';

// Assets
import './MainBanner.scss';
import { ReactComponent as ArrowLeft } from '../../../assets/images/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/svg/arrow-right.svg';
import { useTranslation } from 'react-i18next';
import { getBannersData, updateBanners } from '../../../../features/banners/bannerSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const MainBanner = () => {
  const {
    banners: { data }
  } = useSelector(getBannersData);
  const { i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.key) {
      dispatch(updateBanners());
    }
  }, [dispatch, location.key]);

  const pagination = {
    clickable: true,
    renderBullet(index, className) {
      return `<span class="swiper-pagination-bullet ${className}"></span>`;
    }
  };

  return (
    <section className="MainBanner">
      {/* <Carousel {...sliderProps}>{banners?.length ? banners : <div></div>}</Carousel> */}

      {data ? (
        <>
          <Swiper
            slidesPerView={'auto'}
            centeredSlides={true}
            spaceBetween={8}
            loop
            navigation={{
              nextEl: '.MainBanner__arrow.MainBanner__arrow--right',
              prevEl: '.MainBanner__arrow.MainBanner__arrow--left'
            }}
            pagination={pagination}
            modules={[Pagination, Navigation, Autoplay, EffectFade]}
            breakpoints={{
              360: {
                spaceBetween: 8
              },
              720: {
                spaceBetween: 16
              },
              1440: {
                spaceBetween: 36
              }
            }}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false
            }}
          >
            {data[i18n?.language].map((item) => {
              return (
                <SwiperSlide key={item.link}>
                  <ImgWithFallbackMainBanner
                    key={item.sm}
                    sm={item.sm}
                    md={item.md}
                    lg={item.lg}
                    alt={item.alt}
                    link={item.link}
                    bg={item.bg}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div
            className={`MainBanner__btns-wrap main-slider-pagination ${
              data[i18n?.language]?.length > 1 ? '' : 'hidden'
            }`}
          >
            <button className="MainBanner__arrow MainBanner__arrow--left">
              <ArrowLeft width={20} height={20} />
            </button>
            <button className="MainBanner__arrow MainBanner__arrow--right">
              <ArrowRight width={20} height={20} />
            </button>
          </div>
        </>
      ) : (
        <div className="slider__slide-skeleton" />
      )}
    </section>
  );
};

export default React.memo(MainBanner);
