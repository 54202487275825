import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux stuff
import { useSelector } from 'react-redux';
import { selectAuthInfo } from '../../features/auth/authSlice';
import { useLoaded } from '../helpers/hooks';
import Loader from '../components/common/Loader';

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  const { isAuthorized } = useSelector(selectAuthInfo);
  const { isLoaded } = useLoaded(3000);

  if (isLoaded && !isAuthorized) {
    return <Route {...rest} render={() => <Redirect to="/" />} />;
  }

  return (
    <Route {...rest} render={(props) => (isAuthorized ? <Component {...props} /> : <Loader />)} />
  );
};

export default AuthorizedRoute;

AuthorizedRoute.propTypes = {
  component: PropTypes.elementType.isRequired
};
