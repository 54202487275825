import React from 'react';
import ProfileDropdown from '../../components/profile/profile-dropdown';
import { useLogout } from '../../helpers/hooks';

// Redux
import { useSelector } from 'react-redux';
import { getUserProfile } from '../../../features/user/userSlice';
import { throttle } from '../../helpers/utils';

const ProfileDropdownContainer = () => {
  const { logoutHandle } = useLogout();
  const { name, groups } = useSelector(getUserProfile);
  const groupName = groups?.[0].name;

  return (
    <ProfileDropdown
      name={name}
      handleLogout={throttle(logoutHandle, 3000)}
      groupName={groupName}
    />
  );
};

export default ProfileDropdownContainer;
