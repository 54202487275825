import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../../../assets/images/logo.svg';
import PropTypes from 'prop-types';

const Logo = ({ logoClassName }) => {
  return (
    <Link to="/" className={logoClassName}>
      <LogoIcon />
    </Link>
  );
};

Logo.propTypes = {
  logoClassName: PropTypes.string
};

export default React.memo(Logo);
