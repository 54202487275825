import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/user/userSlice';
import deviceReducer from '../features/devices/devicesSlice';
import paymentsReducer from '../features/payments/paymentsSlice';
import subscriptionsReducer from '../features/subscriptions/subscriptionsSlice';
import parametersReducer from '../features/parameters/parametersSlice';
import videoDetailsReducer from '../features/video-details/videoDetailsSlice';
import videoCollectionsReducer from '../features/video-collections/videoCollectionsSlice';
import videoConfigurationReducer from '../features/video-configuration/videoConfigurationSlice';
import videoSearchReducer from '../features/video-search/videoSearchSlice';
import channelSearchReducer from '../features/channel-search/channelSearchSlice';
import videoFavoritesReducer from '../features/video-favorites/videoFavoritesSlice';
import videoPurchasedReducer from '../features/video-purchased/videoPurchasedSlice';
import videoPremieresReducer from '../features/video-premieres/videoPremieresSlice';
import channelsReducer from '../features/channels/channelsSlice';
import telecastReducer from '../features/telecast-search/telecastSearchSlice';
import channelsCollectionsReducer from '../features/channels-collections/channelsCollectionsSlice';
import seriesReducer from '../features/series/seriesSlice';
import historyReducer from '../features/video-history/videoHistorySlice';
import parentControlReducer from '../features/parentControl/parentControlSlice';
import bannersReducer from '../features/banners/bannerSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    channels: channelsReducer,
    series: seriesReducer,
    channelsCollections: channelsCollectionsReducer,
    channelSearch: channelSearchReducer,
    telecastSearch: telecastReducer,
    payments: paymentsReducer,
    subscriptions: subscriptionsReducer,
    parameters: parametersReducer,
    videoSearch: videoSearchReducer,
    videoDetails: videoDetailsReducer,
    videoCollections: videoCollectionsReducer,
    videoFavorites: videoFavoritesReducer,
    videoPurchased: videoPurchasedReducer,
    videoPremieres: videoPremieresReducer,
    videoConfiguration: videoConfigurationReducer,
    devices: deviceReducer,
    history: historyReducer,
    parentControl: parentControlReducer,
    banners: bannersReducer
  }
});
