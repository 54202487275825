import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';
import { mggFetchVideoEpisodes } from '../../api/videoDetailsApi';

const initialState = {
  status: 'idle',
  currentSeriesId: null,
  seriesData: [],
  seriesDataAll: [],
  seriesDataTemp: []
};

export const fetchVideoEpisodes = createAsyncThunk('series/fetchVideoEpisodes', async (id) => {
  return await mggFetchVideoEpisodes(id);
});

const seriesSlice = createSlice({
  name: 'series',
  initialState,
  reducers: {
    setCurrentSeriesId: (state, action) => {
      state.currentSeriesId = action.payload;
    },
    getAllVideoSeries: (state) => {
      state.seriesDataTemp = [...state.seriesDataAll];
    },

    getInitialVideoSeries: (state) => {
      state.seriesDataTemp = [...state.seriesData];
    },
    setInitialSeries: (state, action) => {
      state.status = initialState.status;
      state.seriesData = initialState.seriesData;
      state.seriesDataAll = initialState.seriesDataAll;
      state.seriesDataTemp = initialState.seriesDataTemp;
      if (action.payload?.clearCurrentSeriesId) {
        state.currentSeriesId = initialState.currentSeriesId;
      }
    }
  },
  extraReducers: {
    [fetchVideoEpisodes.pending]: (state) => {
      state.status = FETCH_STATUS_LOADING;
      state.seriesData = initialState.seriesData;
      state.seriesDataAll = initialState.seriesDataAll;
      state.seriesDataTemp = initialState.seriesDataTemp;
    },
    [fetchVideoEpisodes.fulfilled]: (state, action) => {
      state.status = FETCH_STATUS_SUCCESS;
      state.seriesDataAll = action.payload.data;
      state.seriesData = action.payload.data.slice(0, 4);
      state.seriesDataTemp = action.payload.data.slice(0, 4);
    },
    [fetchVideoEpisodes.rejected]: (state) => {
      state.status = FETCH_STATUS_FAILED;
    }
  }
});

// Selectors
export const getVideoSeries = (state) => state.series.seriesDataTemp;
export const getVideoSeriesAll = (state) => state.series.seriesDataAll;
export const getVideoSeriesLoadingStatus = (state) => state.series.status;
export const getCurrentSeriesId = (state) => state.series.currentSeriesId;

// Actions
export const { getAllVideoSeries, getInitialVideoSeries, setCurrentSeriesId, setInitialSeries } =
  seriesSlice.actions;

// Reducer
export default seriesSlice.reducer;
