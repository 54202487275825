import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { mggFetchVideosByCategory } from '../../api/videoDetailsApi';
import { mggFetchPremieresVideos } from '../../api/videoPremieresApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';
import {
  CARTOONS_CATEGORY_NUMBER,
  MOVIES_CATEGORY_NUMBER,
  SERIES_CATEGORY_NUMBER,
  SHOWS_CATEGORY_NUMBER
} from '../../app/constants/configuration';

const initialState = {
  movies: {
    newestMovies: [],
    popularMovies: [],
    latestAddedMovies: []
  },
  cartoons: {
    newestCartoons: [],
    popularCartoons: [],
    latestAddedCartoons: []
  },
  series: {
    newestSeries: [],
    popularSeries: [],
    latestAddedSeries: []
  },
  show: {
    newestShows: [],
    popularShows: [],
    latestAddedShows: []
  },
  premieres: {
    premieresCollection: []
  },
  videoList: {
    status: 'idle',
    has_more: false,
    limit: 0,
    offset: 0,
    total: 0,
    video_list: []
  },
  videoListFilters: {
    bySubscriptionId: null,
    bySubscriptionTitle: null,
    yearMin: null,
    yearMax: null,
    yearTitle: null,
    genreId: null,
    genreTitle: null,
    country: null
  }
};

export const fetchVideosByCategory = createAsyncThunk(
  'videoCollections/fetchVideosByCategory',
  async (
    {
      categoryId = MOVIES_CATEGORY_NUMBER,
      sort = '',
      limit,
      offset,
      genreId,
      yearMin,
      yearMax,
      country,
      bySubscription
    },
    thunkAPI
  ) => {
    const response = await mggFetchVideosByCategory(
      categoryId,
      sort,
      limit,
      offset,
      genreId,
      yearMin,
      yearMax,
      country,
      bySubscription,
      {
        signal: thunkAPI.signal
      }
    );

    return response;
  }
);

// Fetch movies collections
export const fetchNewestMovies = createAsyncThunk(
  'videoCollections/fetchNewestMovies',
  async () => {
    return await mggFetchVideosByCategory(MOVIES_CATEGORY_NUMBER, 'year');
  }
);

export const fetchPopularMovies = createAsyncThunk(
  'videoCollections/fetchPopularMovies',
  async () => {
    return await mggFetchVideosByCategory(MOVIES_CATEGORY_NUMBER, 'popular');
  }
);

export const fetchLatestAddedMovies = createAsyncThunk(
  'videoCollections/fetchLatestAddedMovies',
  async () => {
    return await mggFetchVideosByCategory(MOVIES_CATEGORY_NUMBER, 'add');
  }
);

// Fetch cartoons collections
export const fetchNewestCartoons = createAsyncThunk(
  'videoCollections/fetchNewestCartoons',
  async () => {
    return await mggFetchVideosByCategory(CARTOONS_CATEGORY_NUMBER, 'year');
  }
);

export const fetchPopularCartoons = createAsyncThunk(
  'videoCollections/fetchPopularCartoons',
  async () => {
    return await mggFetchVideosByCategory(CARTOONS_CATEGORY_NUMBER, 'popular');
  }
);

export const fetchLatestAddedCartoons = createAsyncThunk(
  'videoCollections/fetchLatestAddedCartoons',
  async () => {
    return await mggFetchVideosByCategory(CARTOONS_CATEGORY_NUMBER, 'add');
  }
);

// Fetch series collections
export const fetchNewestSeries = createAsyncThunk(
  'videoCollections/fetchNewestSeries',
  async () => {
    return await mggFetchVideosByCategory(SERIES_CATEGORY_NUMBER, 'year');
  }
);

export const fetchPopularSeries = createAsyncThunk(
  'videoCollections/fetchPopularSeries',
  async () => {
    return await mggFetchVideosByCategory(SERIES_CATEGORY_NUMBER, 'popular');
  }
);

export const fetchLatestAddedSeries = createAsyncThunk(
  'videoCollections/fetchLatestAddedSeries',
  async () => {
    return await mggFetchVideosByCategory(SERIES_CATEGORY_NUMBER, 'add');
  }
);

// Fetch show collections

export const fetchNewestShows = createAsyncThunk('videoCollections/fetchNewestShows', async () => {
  return await mggFetchVideosByCategory(SHOWS_CATEGORY_NUMBER, 'year');
});

export const fetchPopularShows = createAsyncThunk(
  'videoCollections/fetchPopularShows',
  async () => {
    return await mggFetchVideosByCategory(SHOWS_CATEGORY_NUMBER, 'popular');
  }
);

export const fetchLatestAddedShows = createAsyncThunk(
  'videoCollections/fetchLatestAddedShows',
  async () => {
    return await mggFetchVideosByCategory(SHOWS_CATEGORY_NUMBER, 'add');
  }
);

// Fetch premieres collections
export const fetchPremieresCollection = createAsyncThunk(
  'videoCollections/fetchPremieresCollection',
  async () => {
    return await mggFetchPremieresVideos();
  }
);

const videoCollectionsSlice = createSlice({
  name: 'videoCollections',
  initialState,
  reducers: {
    setInitialVideoList: (state) => {
      state.videoList = initialState.videoList;
    },
    updateVideoList: (state, action) => {
      const updatedvideoList = state.videoList.video_list.map((i) => {
        if (i.id === action.payload.id) {
          return action.payload;
        }
        return i;
      });
      state.videoList.video_list = updatedvideoList;
    }
  },
  extraReducers: {
    [fetchNewestMovies.fulfilled]: (state, action) => {
      state.movies.newestMovies = action.payload.data.video_list;
    },
    [fetchPopularMovies.fulfilled]: (state, action) => {
      state.movies.popularMovies = action.payload.data.video_list;
    },
    [fetchLatestAddedMovies.fulfilled]: (state, action) => {
      state.movies.latestAddedMovies = action.payload.data.video_list;
    },
    [fetchNewestCartoons.fulfilled]: (state, action) => {
      state.cartoons.newestCartoons = action.payload.data.video_list;
    },
    [fetchPopularCartoons.fulfilled]: (state, action) => {
      state.cartoons.popularCartoons = action.payload.data.video_list;
    },
    [fetchLatestAddedCartoons.fulfilled]: (state, action) => {
      state.cartoons.latestAddedCartoons = action.payload.data.video_list;
    },
    [fetchNewestSeries.fulfilled]: (state, action) => {
      state.series.newestSeries = action.payload.data.video_list;
    },
    [fetchNewestShows.fulfilled]: (state, action) => {
      state.show.newestShows = action.payload.data.video_list;
    },
    [fetchPopularShows.fulfilled]: (state, action) => {
      state.show.popularShows = action.payload.data.video_list;
    },
    [fetchLatestAddedShows.fulfilled]: (state, action) => {
      state.show.latestAddedShows = action.payload.data.video_list;
    },
    [fetchPopularSeries.fulfilled]: (state, action) => {
      state.series.popularSeries = action.payload.data.video_list;
    },
    [fetchLatestAddedSeries.fulfilled]: (state, action) => {
      state.series.latestAddedSeries = action.payload.data.video_list;
    },
    [fetchPremieresCollection.fulfilled]: (state, action) => {
      state.premieres.premieresCollection = action.payload.data.video_list;
    },
    [fetchVideosByCategory.pending]: (state) => {
      state.videoList.status = FETCH_STATUS_LOADING;
    },
    [fetchVideosByCategory.fulfilled]: (state, action) => {
      state.videoList.status = FETCH_STATUS_SUCCESS;
      state.videoList.video_list = action.payload.data.video_list;
      state.videoList.limit = action.payload.data.limit;
      state.videoList.total = state.videoList.total + action.payload?.data.limit;
      state.videoList.offset = action.payload.data.offset;
      state.videoList.has_more = action.payload.data.has_more;
      state.currentRequestId = undefined;
    },
    [fetchVideosByCategory.rejected]: (state) => {
      state.videoList.status = FETCH_STATUS_FAILED;
    }
  }
});

export const { setInitialVideoList, updateVideoList } = videoCollectionsSlice.actions;

// movies collections
export const getNewestMovies = (state) => state.videoCollections.movies.newestMovies;
export const getPopularMovies = (state) => state.videoCollections.movies.popularMovies;
export const getLatestAddedMovies = (state) => state.videoCollections.movies.latestAddedMovies;

// cartoons collections
export const getNewestCartoons = (state) => state.videoCollections.cartoons.newestCartoons;
export const getPopularCartoons = (state) => state.videoCollections.cartoons.popularCartoons;
export const getLatestAddedCartoons = (state) =>
  state.videoCollections.cartoons.latestAddedCartoons;

// series collections
export const getNewestSeries = (state) => state.videoCollections.series.newestSeries;
export const getPopularSeries = (state) => state.videoCollections.series.popularSeries;
export const getLatestAddedSeries = (state) => state.videoCollections.series.latestAddedSeries;

// shows collections
export const getNewestShow = (state) => state.videoCollections.show.newestShows;
export const getPopularShow = (state) => state.videoCollections.show.popularShows;
export const getLatestAddedShow = (state) => state.videoCollections.show.latestAddedShows;

// premieres collections
export const getPremieresCollection = (state) =>
  state.videoCollections.premieres.premieresCollection;

// filter selector
export const getFullListVideoInfo = (state) => state.videoCollections.videoList;

export default videoCollectionsSlice.reducer;
