import React, { useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoIcon } from '../../assets/images/logo-only.svg';
import { ReactComponent as CloseSvg } from '../../assets/images/svg/close-black.svg';
import './BannerDownloadApp.scss';
import { APP_BANNER_COOKIE_NAME } from '../../constants/configuration';
import { getCookie, setCookie } from '../../helpers/utils';
import { track } from '@amplitude/analytics-browser';

const BannerDownloadApp = () => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (getCookie(APP_BANNER_COOKIE_NAME)) {
      return;
    } else {
      setCookie(APP_BANNER_COOKIE_NAME, 'show', 86400);
      setIsShow(true);
    }
  }, []);

  const openNewTab = (link) => {
    track('Open store from banner download app');
    window.open(link, '_blank');
  };

  const getStoreType = () => {
    if (isAndroid) {
      return 'header.googlePlay';
    }
    if (isIOS) {
      return 'header.appleStore';
    }
    return '';
  };

  const handleClose = () => {
    track('Close banner download app');
    setIsShow(false);
  };

  const downloadAppLink = () => {
    if (isAndroid) {
      openNewTab('https://play.google.com/store/apps/details?id=ua.briz.androidtv&amp;hl=uk');
    }
    if (isIOS) {
      openNewTab('https://apps.apple.com/ua/app/briz-tv/id1539403361?!=ru');
    }
  };

  return (
    <div className={`page-header__banner ${isShow ? 'show' : ''}`}>
      <div
        onClick={handleClose}
        type="button"
        className="page-header__banner-close-btn"
        aria-label="Закрыть"
      >
        <CloseSvg />
      </div>
      <LogoIcon />
      <div className="page-header__banner__logo__description">
        <p className="title">{t('header.brizTv')}</p>
        <p className="subtitle">{t(getStoreType())}</p>
      </div>
      <button onClick={downloadAppLink} type="button" className="page-header__banner__btn-link">
        {t('header.download')}
      </button>
    </div>
  );
};

export default BannerDownloadApp;
