import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MAX_SEARCH_STRING_LENGTH } from '../../../constants/configuration';
import { useTranslation } from 'react-i18next';
import { mapVideoGenres } from '../../../helpers/utils';
import { SearchBarListItem } from './SearchBarListItem';

// Assets
import './SearchBar.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/svg/close.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/svg/search.svg';

const SearchBar = ({
  searchBarValue,
  searchResults,
  isDropdownVisible,
  videoConfigGenres,
  handleSubmitSearchBar,
  handleSearchBarValue,
  handleCloseSearchBar
}) => {
  const { t } = useTranslation();
  const elRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (elRef.current && !elRef.current.contains(event.target)) {
        handleCloseSearchBar(false);
      }
    };
    setTimeout(() => {
      document.addEventListener('click', handleClickOutside);
    });

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleCloseSearchBar]);

  const searchResultsMarkup = searchResults?.length
    ? searchResults.map(({ id, title, year, slug, genres, categories, delivery_rules, image }) => (
        <SearchBarListItem
          key={id}
          id={id}
          title={title}
          year={year}
          slug={slug}
          categories={categories}
          image={image}
          genres={mapVideoGenres(genres, videoConfigGenres)}
          deliveryRules={delivery_rules}
          handleClickListItem={handleCloseSearchBar}
        />
      ))
    : null;

  return (
    <div ref={elRef} className="SearchBar">
      <div className="SearchBar__form">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmitSearchBar(searchBarValue);
          }}
        >
          <input
            className="SearchBar__input"
            type="text"
            name="text"
            maxLength={MAX_SEARCH_STRING_LENGTH}
            autoComplete="off"
            autoFocus
            value={searchBarValue}
            onChange={(event) => handleSearchBarValue(event.target.value)}
          />
          <button
            className="SearchBar__btn SearchBar__btn--close"
            type="reset"
            onClick={handleCloseSearchBar}
          >
            <CloseIcon />
          </button>
          <button className="SearchBar__btn SearchBar__btn--submit" type="submit">
            <SearchIcon />
          </button>
        </form>
      </div>
      <div className={classNames('SearchBar__list-wrap', isDropdownVisible && 'show')}>
        <div className={classNames('SearchBar__list')}>
          <div className="SearchBar__list-items with-scrollbar">{searchResultsMarkup}</div>
          <div
            className="SearchBar__list-link"
            onClick={(event) => {
              event.preventDefault();
              handleSubmitSearchBar(searchBarValue);
            }}
          >
            {t('header.searchBar.allResultsLink')}
          </div>
        </div>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  searchBarValue: PropTypes.string,
  searchResults: PropTypes.array,
  isDropdownVisible: PropTypes.bool,
  videoConfigGenres: PropTypes.array,
  handleSearchBarValue: PropTypes.func.isRequired,
  handleSubmitSearchBar: PropTypes.func.isRequired,
  handleCloseSearchBar: PropTypes.func.isRequired
};

export default SearchBar;
