import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { USER_CABINET_URL } from '../../constants/configuration';
//assets
import './RegisterInternalUserContainer.scss';

// Components
import PortalContainer from '../PortalContainer';
import Modal from '../../components/common/Modal';
import { ReactComponent as Wheel } from '../../assets/images/svg/wheel.svg';

const RegisterInternalUserContainer = ({ onModalClose, modalClassName = '' }) => {
  const { t, i18n } = useTranslation();
  const handleModalClose = useCallback(() => {
    onModalClose();
  }, [onModalClose]);

  const escapeListener = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        handleModalClose();
      }
    },
    [handleModalClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', escapeListener, false);

    return () => {
      document.removeEventListener('keydown', escapeListener, false);
    };
  }, [escapeListener]);

  return (
    <PortalContainer>
      <div className="ModalOverlay">
        <Modal modalClassName={modalClassName} onModalClose={handleModalClose}>
          <Wheel />
          <p className="Modal_RegisterInternalUser__title">{t('youAreInBriz')}</p>
          <p className="Modal_RegisterInternalUser__description">{t('signUpAtBrizUa')}</p>
          <a
            target="_blank"
            className="RegisterInternalUser__proceed"
            href={`${USER_CABINET_URL}?lang=${i18n.language}`}
            rel="noreferrer"
          >
            {t('goToSignUp')}
          </a>
        </Modal>
      </div>
    </PortalContainer>
  );
};

RegisterInternalUserContainer.propTypes = {
  modalClassName: PropTypes.string,
  onModalClose: PropTypes.func
};

export default RegisterInternalUserContainer;
