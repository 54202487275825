import { client } from './api-client';

export const mggFetchVideoInfo = (id) => client.get(`mgg/video/info?id=${id}`);
export const mggFetchRecomendedVideo = (id) => client.get(`mgg/video/recommended?video_id=${id}`);
export const mggFetchVideoEpisodes = (id) => client.get(`mgg/video/episodes?id=${id}`);
export const mggFetchVideoComments = (video_id, offset, limit) =>
  client.get(`mgg/comments?video_id=${video_id}&offset=${offset}&limit=${limit}`);
export const mggPostVideoComment = (body) => client.post(`mgg/comments`, body);
export const mggVoteVideo = (body) => client.post(`mgg/vote`, body);

export const mggFetchVideosByCategory = (
  category_id,
  sort,
  limit,
  offset,
  genre,
  year_min,
  year_max,
  country,
  bySubscription,
  signal
) => {
  const _sort = sort ? `&sort=${sort}` : '';
  const _limit = limit ? `&limit=${limit}` : '';
  const _offset = offset ? `&offset=${offset}` : '';
  const _genre = genre ? `&genre=${genre}` : '';
  const _year_min = year_min ? `&year_min=${year_min}` : '';
  const _year_max = year_max ? `&year_max=${year_max}` : '';
  const _country = country ? `&country=${country}` : '';
  const _by_subscription = bySubscription ? `&product_delivery_rules=${bySubscription}` : '';
  return client.get(
    `mgg/video?category_id=${category_id}${_sort}${_limit}${_offset}${_genre}${_year_min}${_year_max}${_country}${_by_subscription}`,
    signal
  );
};
export const mggFetchVideoStream = (video_id) => client.get(`mgg/stream?video_id=${video_id}`);
export const mggAddFavoriteVideo = (body) => client.post(`mgg/favorites`, body);
export const mggRemoveFavoriteVideo = (body) => client.delete(`mgg/favorites`, body);
