import React from 'react';
import PropTypes from 'prop-types';
import './Avatar.scss';
import { ReactComponent as AvatarIcon } from '../../../assets/images/movies/avatar.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/profile/edit-icon.svg';

const Avatar = ({
  userAvatar,
  userName,
  className = '',
  avatarTag: AvatarTag = 'div',
  editable = false,
  handleEditImage,
  handleImageChange
}) => {
  return (
    <AvatarTag className={`Avatar ${className}`}>
      {userAvatar ? (
        <img src={userAvatar} alt={userName || 'avatar'} />
      ) : (
        <>{userName?.charAt(0).toUpperCase() || <AvatarIcon />}</>
      )}
      {editable && (
        <>
          <button className="EditBtn ripple-effect" onClick={handleEditImage}>
            <EditIcon />
          </button>
          <input
            type="file"
            name="avatar"
            id="imageInput"
            hidden="hidden"
            onChange={handleImageChange}
          />
        </>
      )}
    </AvatarTag>
  );
};

Avatar.propTypes = {
  userAvatar: PropTypes.string,
  userName: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  avatarTag: PropTypes.string,
  editable: PropTypes.bool,
  handleEditImage: PropTypes.func,
  handleImageChange: PropTypes.func
};

export default Avatar;
