import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import ScrollToTop from '../components/common/ScrollToTop';

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Router;
