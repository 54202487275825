import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CardsSlider from '../../cards/cards-slider';
import ChannelCard from '../channel-card';
import { ChannelCardSkeleton } from '../channel-card/ChannelCard';
import './ChannelsSlider.scss';

const ChannelsSlider = ({ channels, heading, updateFavoriteChannels }) => {
  return (
    <CardsSlider
      sliderClassName="ChannelsSlider"
      heading={heading}
      headingHref="/tv-channels"
      isHeadingLink={true}
      cardComponent={({ data }) => (
        <ChannelCard
          key={data.id}
          channelId={data.id}
          channelImgSrc={data.logo}
          channelTitle={data.name}
          genre_id={data.genre_id}
          isChannelLocked={data.briz_billing_product_info?.is_available === false}
          isFavorite={data.briz_billing_product_info?.in_favorites}
          channel={data}
          updateFavoriteChannels={updateFavoriteChannels}
        />
      )}
      skeletonComponent={() => <ChannelCardSkeleton />}
      items={channels}
      itemsLength={6}
    />
  );
};

ChannelsSlider.propTypes = {
  channels: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
  updateFavoriteChannels: PropTypes.func
};

export default memo(ChannelsSlider);
