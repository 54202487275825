import React from 'react';

// Assets
import './Header.scss';

// Components
import Logo from './Logo';
import HeaderToolBarContainer from '../../../containers/HeaderToolBarContainer';
import NavBarContainer from '../../../containers/NavBarContainer';
import HeaderBurgerContainer from '../../../containers/HeaderBurgerContainer/HeaderBurgerContainer';

const Header = () => {
  return (
    <header className="Header">
      <div className="HeaderContainer Container">
        <HeaderBurgerContainer />
        <Logo logoClassName="HeaderLogo" />
        <NavBarContainer />
        <HeaderToolBarContainer />
      </div>
    </header>
  );
};

export default React.memo(Header);
