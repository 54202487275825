import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isValidJson } from '../../app/helpers/utils';
import {
  mggFetchVideoInfo,
  mggFetchVideoComments,
  mggVoteVideo,
  mggPostVideoComment,
  mggFetchVideoStream,
  mggAddFavoriteVideo,
  mggRemoveFavoriteVideo
} from '../../api/videoDetailsApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';
import { createSelector } from 'reselect';

const initialState = {
  video: {
    videoId: null,
    status: '',
    videoData: {}
  },
  videoStream: {
    status: 'idle',
    error: null,
    errorData: [],
    data: {
      restrictions: null
    }
  },
  trailer: {
    status: 'idle',
    trailerData: {}
  },
  commentsId: null,
  comments: {
    status: 'idle',
    total: 0,
    offset: 0,
    limit: 10,
    hasMore: false,
    commentsData: []
  },
  vote: {
    status: 'idle'
  },
  isFavorite: {
    status: 'idle'
  }
};

export const fetchVideoInfo = createAsyncThunk('videoDetails/fetchVideoInfo', async (id) => {
  return await mggFetchVideoInfo(id);
});

export const fetchVideoStream = createAsyncThunk(
  'videoDetails/fetchVideoStream',
  async (videoId) => {
    return await mggFetchVideoStream(videoId);
  }
);

export const fetchVideoTrailer = createAsyncThunk(
  'videoDetails/fetchVideoTrailer',
  async (trailerId) => {
    return await mggFetchVideoStream(trailerId);
  }
);

export const fetchVideoComments = createAsyncThunk(
  'videoDetails/fetchVideoComments',
  async ({ video_id, offset = 0, limit = 10 }) => {
    return await mggFetchVideoComments(video_id, offset, limit);
  }
);

export const fetchVideoCommentsTotal = createAsyncThunk(
  'videoDetails/fetchVideoCommentsTotal',
  async ({ video_id, offset = 0, limit = 10 }) => {
    return await mggFetchVideoComments(video_id, offset, limit);
  }
);

export const postVideoComment = createAsyncThunk('videoDetails/postVideoComment', async (body) => {
  return await mggPostVideoComment(body);
});

export const voteVideo = createAsyncThunk('videoDetails/voteVideo', async (body) => {
  return await mggVoteVideo(body);
});

export const addFavoriteVideo = createAsyncThunk('videoDetails/addFavoriteVideo', async (body) => {
  return await mggAddFavoriteVideo(body);
});

export const removeFavoriteVideo = createAsyncThunk(
  'videoDetails/removeFavoriteVideo',
  async (body) => {
    return await mggRemoveFavoriteVideo(body);
  }
);

const videoDetailsSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    setVideoId: (state, action) => {
      state.video.videoId = action.payload;
    },
    resetVideoId: (state) => {
      state.video.videoId = initialState.video.videoId;
    },
    resetVideoStreamData: (state) => {
      state.videoStream = initialState.videoStream;
    },
    resetVideoTrailer: (state) => {
      state.trailer.trailerData = initialState.trailer.trailerData;
    },
    resetComments: (state) => {
      state.comments.commentsData = initialState.comments.commentsData;
      state.comments.hasMore = initialState.comments.hasMore;
      state.comments.offset = initialState.comments.offset;
    }
  },
  extraReducers: {
    [fetchVideoInfo.pending]: (state) => {
      state.video.status = FETCH_STATUS_LOADING;
      state.commentsId = initialState.commentsId;
      state.comments = initialState.comments;
      state.videoStream = initialState.videoStream;
      state.trailer.trailerData = initialState.trailer.trailerData;
      state.video.videoData = initialState.video.videoData;
    },
    [fetchVideoInfo.fulfilled]: (state, action) => {
      state.video.status = FETCH_STATUS_SUCCESS;
      state.video.videoData =
        Object.keys(state.video.videoData).length === 1 && state.video.videoData.is_favorite
          ? { ...action.payload.data, ...state.video.videoData }
          : action.payload.data;
      state.video.videoId = action.payload.data.id;
      state.commentsId = action.payload.data?.in_history_root_video_id || action.payload.data.id;
    },
    [fetchVideoInfo.rejected]: (state, action) => {
      state.video.status = FETCH_STATUS_FAILED;
      state.error = action.error;
    },
    [fetchVideoStream.pending]: (state) => {
      state.videoStream.status = FETCH_STATUS_LOADING;
    },
    [fetchVideoStream.fulfilled]: (state, action) => {
      state.videoStream.status = FETCH_STATUS_SUCCESS;
      state.videoStream.data = {
        videoSrc: action.payload.data.src,
        subtitles: action.payload.data.subtitles,
        audio_tracks: action.payload.data.audio_tracks,
        bitrates: action.payload.data.bitrates?.reverse(),
        restrictions: action.payload.data.restrictions,
        next: action.payload.data.next,
        prev: action.payload.data.prev
      };
    },
    [fetchVideoStream.rejected]: (state, action) => {
      if (isValidJson(action.error.message)) {
        const errorData = JSON.parse(action.error.message);
        state.videoStream.error = errorData.error;
        state.videoStream.errorData = errorData.data;
      } else {
        state.videoStream.error = action.error.message;
      }
      state.videoStream.status = FETCH_STATUS_FAILED;
    },
    [fetchVideoTrailer.pending]: (state) => {
      state.trailer.status = FETCH_STATUS_LOADING;
    },
    [fetchVideoTrailer.fulfilled]: (state, action) => {
      state.trailer.status = FETCH_STATUS_SUCCESS;
      state.trailer.trailerData = {
        trailerSrc: action.payload.data.src
      };
    },
    [fetchVideoTrailer.rejected]: (state, action) => {
      state.trailer.status = FETCH_STATUS_FAILED;
      state.error = action.error;
    },
    [fetchVideoCommentsTotal.fulfilled]: (state, action) => {
      state.comments.status = FETCH_STATUS_SUCCESS;
      state.comments.total = action.payload.data.total;
    },
    [fetchVideoComments.pending]: (state) => {
      state.comments.status = FETCH_STATUS_LOADING;
    },
    [fetchVideoComments.fulfilled]: (state, action) => {
      state.comments.status = FETCH_STATUS_SUCCESS;
      state.comments.commentsData.push(...action.payload.data.comments);
      state.comments.total = action.payload.data.total;
      state.comments.limit = action.payload.data.limit;
      state.comments.offset = action.payload.data.offset;
      state.comments.hasMore = action.payload.data.has_more;
    },
    [fetchVideoComments.rejected]: (state) => {
      state.comments.status = FETCH_STATUS_FAILED;
    },
    [postVideoComment.fulfilled]: (state, action) => {
      state.comments.total += 1;
      state.comments.commentsData = [action.payload.data, ...state.comments.commentsData];
    },
    [voteVideo.pending]: (state) => {
      state.vote.status = FETCH_STATUS_LOADING;
    },
    [voteVideo.fulfilled]: (state, action) => {
      state.vote.status = FETCH_STATUS_SUCCESS;
      // update video info: like, dislike, vote
      state.video.videoData = { ...state.video.videoData, ...action.payload.data };
    },
    [voteVideo.rejected]: (state) => {
      state.vote.status = FETCH_STATUS_FAILED;
    },
    [addFavoriteVideo.pending]: (state) => {
      state.isFavorite.status = FETCH_STATUS_LOADING;
    },
    [addFavoriteVideo.fulfilled]: (state) => {
      state.isFavorite.status = FETCH_STATUS_SUCCESS;
      state.video.videoData.is_favorite = true;
    },
    [addFavoriteVideo.rejected]: (state) => {
      state.isFavorite.status = FETCH_STATUS_FAILED;
    },
    [removeFavoriteVideo.pending]: (state) => {
      state.isFavorite.status = FETCH_STATUS_LOADING;
    },
    [removeFavoriteVideo.fulfilled]: (state) => {
      state.isFavorite.status = FETCH_STATUS_SUCCESS;
      state.video.videoData.is_favorite = false;
    },
    [removeFavoriteVideo.rejected]: (state) => {
      state.isFavorite.status = FETCH_STATUS_FAILED;
    }
  }
});

// Selectors
export const getVideoInfo = (state) => state.videoDetails.video.videoData;
export const getVideoPurchaseInfo = (state) => state.videoDetails.video.videoData.purchase_info;
export const getVideoSubscriptionInfo = (state) =>
  state.videoDetails.video.videoData.briz_billing_product_info;

export const getVideoPurchaseTariffs = createSelector(
  [getVideoPurchaseInfo],
  (purchaseInfo) => purchaseInfo?.tvod?.subscriptions[0].tariffs
);

export const getVideoPurchasedStatus = createSelector(
  [getVideoPurchaseInfo],
  (purchaseInfo) => purchaseInfo?.tvod?.subscriptions[0].is_bought
);

export const getVideoInSubscriptionStatus = createSelector(
  [getVideoSubscriptionInfo],
  (subscriptionInfo) => subscriptionInfo?.in_subscription
);

export const getVideoSubscriptions = createSelector(
  [getVideoSubscriptionInfo],
  (subscriptionInfo) => subscriptionInfo?.products
);

export const getVideoPurchasedBySubscriptionStatus = createSelector(
  [getVideoSubscriptionInfo],
  (subscriptionInfo) =>
    subscriptionInfo?.products?.some((subscription) => subscription.is_bought === true)
);

export const getVideoBestAvailableSubscription = createSelector(
  [getVideoSubscriptions],
  (subscriptions) =>
    subscriptions.reduce((prev, current) => (prev.price > current.price ? prev : current), {})
);

export const getVideoId = (state) => state.videoDetails.video.videoId;
export const getCommentsId = (state) => state.videoDetails.commentsId;
export const getVideoLoadingStatus = (state) => state.videoDetails.video.status;
export const getVideoVoteLoadingStatus = (state) => state.videoDetails.vote.status;
export const getIsFavoriteVideoLoadingStatus = (state) => state.videoDetails.isFavorite.status;
// Video stream info
export const getVideoStreamInfo = (state) => state.videoDetails.videoStream.data;
export const getVideoStreamLoadingStatus = (state) => ({
  error: state.videoDetails.videoStream.error,
  errorData: state.videoDetails.videoStream.errorData
});
// Video trailer info
export const getVideoTrailerInfo = (state) => state.videoDetails.trailer.trailerData;
// Video comments info
export const getVideoCommentsInfo = (state) => state.videoDetails.comments;
export const getVideoCommentsAmount = (state) => state.videoDetails.comments.total;

export const {
  setVideoId,
  resetVideoId,
  resetRecomendedVideo,
  resetComments,
  resetVideoStreamData,
  resetVideoTrailer
} = videoDetailsSlice.actions;

export default videoDetailsSlice.reducer;
