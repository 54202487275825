import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

// Assets
import './CardsItem.scss';
import { toPathNameByVideoCategory } from '../../../helpers/utils';
import { useSelector, useDispatch } from 'react-redux';
import { getConfigurationGenres } from '../../../../features/video-configuration/videoConfigurationSlice';
import { useTranslation } from 'react-i18next';
import { track } from '@amplitude/analytics-browser';
import { ReactComponent as FavoriteIcon } from '../../../assets/images/svg/favorite.svg';
import { ReactComponent as UnfavoriteIcon } from '../../../assets/images/svg/unfavorite.svg';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  removeFavoriteVideo,
  addFavoriteVideo
} from '../../../../features/video-details/videoDetailsSlice';
import { selectAuthInfo } from '../../../../features/auth/authSlice';
import { toast } from 'react-toastify';

const CardsItem = ({ data, withDescription = true, updateVideoList = () => {} }) => {
  const {
    title,
    year,
    slug,
    age_limit,
    image,
    id,
    categories,
    genres,
    delivery_rules,
    in_favorites,
    video_type
  } = data;
  const { t } = useTranslation();
  const configGenres = useSelector(getConfigurationGenres);
  const mainGenre = configGenres.find((i) => i.id === genres?.[0])?.title;
  const location = useLocation();
  const { isAuthorized } = useSelector(selectAuthInfo);
  const videoConfigGenres = useSelector(getConfigurationGenres);
  const [disableHandleFavoriteChannel, setDisableHandleFavoriteChannel] = useState(false);
  const dispatch = useDispatch();
  const analytycEvent = () => {
    if (location.pathname.includes('search')) {
      const eventProperties = {
        ContentId: id,
        ContentTitle: title,
        ContentGenre: genres.reduce((acc, i) => {
          acc.push(
            videoConfigGenres?.find((genre) => {
              return genre.id === i;
            })?.path
          );
          return acc;
        }, []),
        ContentType: toPathNameByVideoCategory(categories),
        ContentDistribution: delivery_rules
      };
      track('Search Result Clicked', eventProperties);
    }
  };
  const handleFavoriteVideo = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        if (disableHandleFavoriteChannel) return;
        const eventProperties = {
          ContentId: id,
          ContentTitle: title,
          ContentGenre: genres.reduce((acc, i) => {
            acc.push(
              videoConfigGenres?.find((genre) => {
                return genre.id === i;
              })?.path
            );
            return acc;
          }, []),
          ContentType: video_type,
          ContentDistribution: delivery_rules
        };
        setDisableHandleFavoriteChannel(true);
        if (in_favorites) {
          await unwrapResult(await dispatch(removeFavoriteVideo({ video_id: id })));
          await updateVideoList({ ...data, in_favorites: false });
          toast.error(t('notifications.WAS_REMOVED_FROM_FAVORITES', { name: title }));
          track('Content Unfavorited', eventProperties);
        } else {
          await unwrapResult(await dispatch(addFavoriteVideo({ video_id: id })));
          await updateVideoList({ ...data, in_favorites: true });
          toast.success(t('notifications.WAS_ADDED_TO_FAVORITES', { name: title }));
          track('Content Favorited', eventProperties);
        }
        setDisableHandleFavoriteChannel(false);
      } catch (err) {
        console.error(err);
      }
    },
    [
      data,
      delivery_rules,
      disableHandleFavoriteChannel,
      dispatch,
      genres,
      id,
      in_favorites,
      t,
      title,
      updateVideoList,
      videoConfigGenres,
      video_type
    ]
  );

  const handleSaveScrollPosition = () => {
    sessionStorage.setItem(location.pathname, window.scrollY);
  };

  return (
    <div className="CardsItem" onClick={() => analytycEvent()}>
      <div className="CardsItem__wrapper">
        <Link
          className="CardsItem__poster"
          to={`/${toPathNameByVideoCategory(categories)}/${id}-${slug}`}
          onClick={handleSaveScrollPosition}
        >
          {in_favorites === undefined
            ? null
            : isAuthorized && (
                <button
                  onClick={(e) => handleFavoriteVideo(e)}
                  className="ChannelCard__favorites-btn"
                  type="button"
                >
                  {in_favorites ? <FavoriteIcon /> : <UnfavoriteIcon />}
                </button>
              )}
          <div className="CardsItem__image">
            {/* <img src={image?.small} alt={title} /> */}
            {image && (
              <picture>
                <source srcSet={`${image?.original}/pt/r386x544x4`} type="image/webp" />
                <img
                  src={image?.small}
                  srcSet={`${image?.original}/pt/r193x272 1x, ${image?.original}/pt/r386x544 2x`}
                  alt={title}
                  loading="lazy"
                />
              </picture>
            )}
            <span className="CardsItem__badge">{age_limit}+</span>
          </div>
          <div className="CardsItem__overlay">
            <div className="CardsItem__overlay-title">
              <i className="CardsItem__overlay-play-icon" />
              {t('common.watch')}
            </div>
          </div>
        </Link>
      </div>
      {withDescription && title && (
        <>
          <div>
            <Link
              className="CardsItem__name"
              to={`/${toPathNameByVideoCategory(categories)}/${id}-${slug}`}
            >
              {title}
            </Link>
          </div>
          <div className="CardsItem__genre">
            {year}, {mainGenre}
          </div>
          {delivery_rules?.includes('tvod') ? (
            <div className="CardsItem__label CardsItem__label--purchase">
              {t('common.purchase')}
            </div>
          ) : (
            <div className="CardsItem__label CardsItem__label--subscribe">
              {t('common.subscription')}
            </div>
          )}
        </>
      )}
    </div>
  );
};

CardsItem.propTypes = {
  data: PropTypes.object.isRequired,
  withDescription: PropTypes.bool,
  updateVideoList: PropTypes.func
};

export default memo(CardsItem);

export const CardsItemSkeleton = () => {
  return (
    <div className="CardsItemSkeleton Skeleton">
      <div className="CardsItemSkeleton__image" />
      <div className="CardsItemSkeleton__title" />
      <div className="CardsItemSkeleton__extra" />
      <div className="CardsItemSkeleton__subscription" />
    </div>
  );
};
