import React from 'react';

// Components
import FooterNav from './FooterNav';
import FooterCopyright from './FooterCopyright';

// Assets
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="Footer">
      <FooterNav />
      <FooterCopyright />
    </footer>
  );
};

export default React.memo(Footer);
