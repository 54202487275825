import React from 'react';
import './Burger.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Burger = ({ isVisible, isOpened, handleToggleBurger }) => {
  return (
    isVisible && (
      <div
        className={classNames('Burger', isOpened && 'Burger--opened')}
        onClick={handleToggleBurger}
      >
        <span className="Burger__line" />
        <span className="Burger__line" />
        <span className="Burger__line" />
      </div>
    )
  );
};

Burger.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isOpened: PropTypes.bool.isRequired,
  handleToggleBurger: PropTypes.func.isRequired
};

export default React.memo(Burger);
