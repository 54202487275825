import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchGetProfile,
  fetchUpdateProfile,
  fetchUploadAvatar,
  mggFetchUserRecommendedVideos,
  userChangePassword
} from '../../api/userApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  profile: {
    data: {
      avatar: '',
      name: '',
      email: null,
      phone: null,
      birthday: null,
      location: {}
    },
    status: 'idle',
    error: null
  },
  userRecommendedVideoList: []
};

export const getProfile = createAsyncThunk('user/getProfile', async (body) => {
  return await fetchGetProfile(body);
});

export const updateProfile = createAsyncThunk('user/updateProfile', async (body) => {
  return await fetchUpdateProfile(body);
});

export const changePassword = createAsyncThunk('user/changePassword', async (body) => {
  return await userChangePassword(body);
});

export const uploadProfileImage = createAsyncThunk('user/uploadProfileImage', async (body) => {
  return await fetchUploadAvatar(body);
});

export const fetchUserRecommendedVideos = createAsyncThunk(
  'user/fetchUserRecommendedVideos',
  async ({ limit }) => {
    return await mggFetchUserRecommendedVideos(limit);
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetRecommendedVideos: (state) => {
      state.userRecommendedVideoList = initialState.userRecommendedVideoList;
    },
    setUserDataProfile: (state, action) => {
      state.profile.data = action.payload;
    },
    removeProfileData: (state) => {
      state.profile = initialState.profile;
    }
  },
  extraReducers: {
    [getProfile.pending]: (state) => {
      state.profile.status = FETCH_STATUS_LOADING;
    },
    [getProfile.fulfilled]: (state, action) => {
      state.profile.status = FETCH_STATUS_SUCCESS;
      state.profile.data = action.payload.data;
    },
    [getProfile.rejected]: (state, action) => {
      state.profile.status = FETCH_STATUS_FAILED;
      state.profile.error = action.error;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.profile.data = action.payload.data;
    },
    [uploadProfileImage.pending]: (state) => {
      state.profile.status = FETCH_STATUS_LOADING;
    },
    [uploadProfileImage.fulfilled]: (state, action) => {
      state.profile.status = FETCH_STATUS_SUCCESS;
      state.profile.data.avatar = action.payload.data.avatar;
    },
    [uploadProfileImage.rejected]: (state) => {
      state.profile.status = FETCH_STATUS_FAILED;
    },
    [fetchUserRecommendedVideos.fulfilled]: (state, action) => {
      state.userRecommendedVideoList = action.payload.data.video_list;
    }
  }
});

// Actions
export const { resetRecommendedVideos, setUserDataProfile, removeProfileData } = userSlice.actions;

// Selectors
export const getUserProfile = (state) => state.user.profile.data;
export const getUserProfileStatus = (state) => state.user.profile.status;
export const getUserRecommendedVideoList = (state) => state.user.userRecommendedVideoList;

// Reducer
export default userSlice.reducer;
