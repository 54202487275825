import React, { useEffect } from 'react';
import Router from './routes';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import './assets/styles/main.scss';
import { toast } from 'react-toastify';
import { checkWebpSupport } from './helpers/utils';
import { init } from '@amplitude/analytics-browser';
import { identify, Identify, setUserId } from '@amplitude/analytics-browser';
import { AmplitudeKey, CrazyEgg } from './constants/configuration';
import RocketLogContainer from './containers/RocketLogContainer';
import { useLogRocket } from './helpers/useLogRocket';
import { useLogout } from './helpers/hooks';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchVideoConfiguration } from '../features/video-configuration/videoConfigurationSlice';
import { getProfile, getUserProfile } from '../features/user/userSlice';
import { getStatusParentControl } from '../features/parentControl/parentControlSlice';
import {
  refreshToken,
  selectAuthInfo,
  setAuthorized,
  setAuthorizedData,
  getAccessToken
} from '../features/auth/authSlice';
import { fetchBanners } from '../features/banners/bannerSlice';

const App = () => {
  const { i18n } = useTranslation();
  const { isAuthorized } = useSelector(selectAuthInfo);
  const { logoutHandle } = useLogout();
  const dispatch = useDispatch();
  const accessToken = useSelector(getAccessToken);
  const { name, id, email, location, billing_id, groups, phone } = useSelector(getUserProfile);
  const { isShowRocketLogMode, setUserInfo } = useLogRocket();

  useEffect(() => {
    unwrapResult(dispatch(fetchBanners()));
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      setUserInfo({ id, name, email });
    }
  }, [email, id, name, setUserInfo]);
  useEffect(() => {
    const identifyObj = new Identify();
    if (id) {
      setUserId(id);
      identifyObj.setOnce('UserName', name);
      identifyObj.setOnce('UserId', id);
      identifyObj.setOnce('UserLogin', `${email}, ${phone}`);
      identifyObj.setOnce('location', location);
      identifyObj.setOnce('BillingId', billing_id);
      identifyObj.setOnce('AccountType', groups?.[0].name);
    }
    identify(identifyObj);
  }, [billing_id, email, groups, id, location, name, phone]);

  useEffect(() => {
    toast.configure();
    const e = document.documentElement;
    checkWebpSupport().then((isWebpSupported) => {
      isWebpSupported &&
        (0 <= e.className.indexOf('no-webp')
          ? (e.className = e.className.replace(/\bno-webp\b/, 'webp'))
          : (e.className += ' webp'));
    });
    if (navigator.userAgent.indexOf('Win') !== -1) {
      e.classList.add('scrollbar-thin');
    }
  }, []);

  useEffect(() => {
    // init Amplitude SDK
    AmplitudeKey && init(AmplitudeKey, null, { minIdLength: 1 });
  }, []);

  useEffect(() => {
    if (CrazyEgg) {
      const script = document.createElement('script');
      script.src = '//script.crazyegg.com/pages/scripts/0068/4662.js';
      script.async = true;
      script.type = 'text/javascript';
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    const refresh_token = localStorage.getItem('refresh_token');
    const access_token = localStorage.getItem('access_token');
    dispatch(setAuthorizedData({ refresh_token, access_token }));
  }, [dispatch]);

  useEffect(() => {
    let timeout;
    const maxTimeOutTime = 2147483647;
    let timeoutTime;
    const refresh_token = localStorage.getItem('refresh_token');
    try {
      if (accessToken) {
        const decodedToken = jwtDecode(accessToken);
        if (decodedToken.exp * 1000 > Date.now()) {
          let millisecondsForExpired = decodedToken.exp * 1000 - Date.now() - 120000;
          timeoutTime =
            millisecondsForExpired > maxTimeOutTime ? maxTimeOutTime : millisecondsForExpired;
          timeout = window.setTimeout(() => {
            console.log('access_token has been expired, need refresh another...', new Date());
            dispatch(refreshToken({ refresh_token }));
          }, timeoutTime);
          dispatch(setAuthorized(true));
        } else {
          (async () => {
            const refreshResult = await unwrapResult(dispatch(refreshToken({ refresh_token })));
            if (refreshResult?.payload) {
              dispatch(setAuthorized(true));
            }
          })();
        }
      }
    } catch (err) {
      logoutHandle();
    }

    return () => {
      window.clearTimeout(timeout);
    };
  }, [accessToken, dispatch, logoutHandle]);

  /**
   * Fetch video configuration
   * It is needed to build associations between the category identifier, name, genre.
   */
  useEffect(() => {
    let isMounted = true;

    (async () => {
      try {
        isMounted && unwrapResult(await dispatch(fetchVideoConfiguration()));
      } catch (e) {
        console.log(e);
      }
    })();

    return () => (isMounted = false);
  }, [dispatch, i18n.language, isAuthorized]);

  /**
   * Get profile data when user is authorized
   */
  useEffect(() => {
    (async () => {
      try {
        isAuthorized && unwrapResult(await dispatch(getProfile()));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [dispatch, isAuthorized]);

  useEffect(() => {
    (async () => {
      try {
        isAuthorized && unwrapResult(await dispatch(getStatusParentControl()));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [dispatch, isAuthorized]);

  return (
    <React.StrictMode>
      <Router />
      <RocketLogContainer isShow={isShowRocketLogMode} />
    </React.StrictMode>
  );
};

export default App;
