import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './PlayButton.scss';

const PlayButton = ({ handlePlayButton, playButtonClassName, dataContent = '' }) => {
  return (
    <button
      data-content={dataContent}
      className={classNames('PlayButton', playButtonClassName)}
      onClick={handlePlayButton}
    />
  );
};

PlayButton.propTypes = {
  playButtonClassName: PropTypes.string,
  handlePlayButton: PropTypes.func,
  dataContent: PropTypes.string
};

export default PlayButton;
