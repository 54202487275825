import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getParameters } from '../../api/parametersApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  parametersData: {
    data: [],
    status: 'idle',
    error: null
  }
};

export const fetchParameters = createAsyncThunk('parameters/fetchParameters', async () => {
  return await getParameters();
});

const parametersSlice = createSlice({
  name: 'parameters',
  initialState,
  extraReducers: {
    [fetchParameters.pending]: (state) => {
      state.parametersData.status = FETCH_STATUS_LOADING;
    },
    [fetchParameters.fulfilled]: (state, action) => {
      state.parametersData.status = FETCH_STATUS_SUCCESS;
      state.parametersData.data = action.payload.data;
    },
    [fetchParameters.rejected]: (state, action) => {
      state.parametersData.status = FETCH_STATUS_FAILED;
      state.parametersData.error = action.payload;
    }
  }
});

// Selectors
export const getParametersData = (state) => state.parameters.parametersData.data;

export default parametersSlice.reducer;
