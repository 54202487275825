import React from 'react';
import { useLocation } from 'react-router-dom';
import ToolBar from '../../components/common/Header/ToolBar';
import { useViewport } from '../../helpers/hooks';

// Redux stuff
import { useSelector } from 'react-redux';
import { selectAuthInfo } from '../../../features/auth/authSlice';
import { getUserProfile, getUserProfileStatus } from '../../../features/user/userSlice';
import { DESKTOP_SD_VIEWPORT_SIZE, STATUS_SUCCESS } from '../../constants/configuration';

const HeaderToolBarContainer = () => {
  const { viewportWidth } = useViewport();
  const { isAuthorized, statusIsAuthorized } = useSelector(selectAuthInfo);
  const user = useSelector(getUserProfile);
  const userStatus = useSelector(getUserProfileStatus);
  const location = useLocation();

  const isLoading = userStatus === STATUS_SUCCESS;
  return (
    <ToolBar
      isDesktop={viewportWidth >= DESKTOP_SD_VIEWPORT_SIZE}
      isAuthorized={isAuthorized}
      statusIsAuthorized={statusIsAuthorized}
      location={location}
      user={user}
      isLoading={isLoading}
    />
  );
};

export default HeaderToolBarContainer;
