import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './ProfileDropdown.scss';
import { GROUP_NAME_EXTERNAL } from '../../../constants/configuration.js';

const ProfileDropdown = ({ handleLogout, groupName }) => {
  const { t } = useTranslation();

  return (
    <div className="ProfileDropdown__wrap">
      <div className="ProfileDropdown">
        <div className="ProfileDropdown__header">
          <NavLink to="/profile" className="ProfileDropdown__title">
            {t('header.profileDropdown.profile')}
          </NavLink>
        </div>
        <div className="ProfileDropdown__header">
          <NavLink to="/change-password" className="ProfileDropdown__title">
            {t('header.profileDropdown.password')}
          </NavLink>
        </div>
        {groupName === GROUP_NAME_EXTERNAL && (
          <div className="ProfileDropdown__header">
            <NavLink className="ProfileDropdown__title" to="/subscriptions" exact>
              {t('header.profileDropdown.subscriptions')}
            </NavLink>
          </div>
        )}
        <div className="ProfileDropdown__header">
          <NavLink to="/parent-control" className="ProfileDropdown__title">
            {t('header.profileDropdown.parentControl')}
          </NavLink>
        </div>
        <div className="ProfileDropdown__header">
          <NavLink to="/devices" className="ProfileDropdown__title">
            {t('header.profileDropdown.devices')}
          </NavLink>
        </div>
        <div className="ProfileDropdown__header">
          <NavLink to="/my-cards" className="ProfileDropdown__title">
            {t('header.profileDropdown.cards')}
          </NavLink>
        </div>
        <div className="ProfileDropdown__footer">
          <span className="ProfileDropdown__logout" onClick={handleLogout}>
            {t('header.logoutBtn')}
          </span>
        </div>
      </div>
    </div>
  );
};

ProfileDropdown.propTypes = {
  groupName: PropTypes.string,
  handleLogout: PropTypes.func.isRequired
};

export default ProfileDropdown;
