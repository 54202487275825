import React from 'react';
import { createPortal } from 'react-dom';

const PortalContainer = ({
  children,
  className = 'modal-root',
  element = 'div',
  isCheckModalOpened = true
}) => {
  const [container] = React.useState(() => {
    const el = document.createElement(element);
    el.classList.add(className);
    return el;
  });

  React.useEffect(() => {
    document.body.appendChild(container);
    if (isCheckModalOpened) document.body.setAttribute('class', 'is-modal-opened');

    return () => {
      document.body.removeAttribute('class');
      document.body.removeChild(container);
    };
  }, [container, isCheckModalOpened]);

  return createPortal(children, container);
};

export default PortalContainer;
