import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { mggFetchPurchasedVideos } from '../../api/userApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  purchased: {
    status: 'idle',
    hasMore: false,
    limit: 0,
    offset: 0,
    total: 0,
    videoList: [],
    error: null
  }
};

export const fetchPurchasedVideos = createAsyncThunk(
  'videoPurchased/fetchPurchasedVideos',
  async ({ limit }) => {
    return await mggFetchPurchasedVideos(limit);
  }
);

const videoPurchasedSlice = createSlice({
  name: 'videoPurchased',
  initialState,
  reducers: {
    setInitialPurchasedVideos: (state) => {
      state.purchased = initialState.purchased;
    },
    updatePurchasedVideos: (state, action) => {
      const updatedList = state.purchased.videoList.map((i) => {
        if (i.id === action.payload.id) {
          return action.payload;
        } else {
          return i;
        }
      });
      state.purchased.videoList = updatedList;
    }
  },
  extraReducers: {
    [fetchPurchasedVideos.pending]: (state) => {
      state.purchased.status = FETCH_STATUS_LOADING;
    },
    [fetchPurchasedVideos.fulfilled]: (state, action) => {
      state.purchased.status = FETCH_STATUS_SUCCESS;
      state.purchased.videoList = action.payload.data.video_list;
      state.purchased.total = action.payload.data.total;
      state.purchased.limit = action.payload.data.limit;
      state.purchased.offset = action.payload.data.offset;
      state.purchased.hasMore = action.payload.data.has_more;
    },
    [fetchPurchasedVideos.rejected]: (state, action) => {
      state.favorites.status = FETCH_STATUS_FAILED;
      state.favorites.error = action.error;
    }
  }
});

export const getPurchasedVideosList = (state) => state.videoPurchased.purchased.videoList;
export const getPurchasedVideosStatus = (state) => state.videoPurchased.purchased.status;
export const getPurchasedVideosTotal = (state) => state.videoPurchased.purchased.total;
export const getPurchasedVideosHasMore = (state) => state.videoPurchased.purchased.hasMore;
export const getPurchasedVideosOffset = (state) => state.videoPurchased.purchased.offset;
export const getPurchasedVideosLimit = (state) => state.videoPurchased.purchased.limit;

export const { setInitialPurchasedVideos, updatePurchasedVideos } = videoPurchasedSlice.actions;

export default videoPurchasedSlice.reducer;
