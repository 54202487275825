import React, { Suspense } from 'react';
import { render } from 'react-dom';
import store from './app/store';
import App from './app/App';
import './i18n';

// Redux stuff
import { Provider } from 'react-redux';
import Loader from './app/components/common/Loader';

// here we can dispatch something before App init
// store.dispatch();

// suspend rendering of the <App> component until i18next has initialized,
// which now depends on the first language file completing its download.
render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('app-root')
);
