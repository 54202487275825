import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import RecommendedList from '../../components/sections/recommended-list';
import CardsSlider from '../../components/cards/cards-slider';
import { useTranslation } from 'react-i18next';

// Redux
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserRecommendedVideos,
  resetRecommendedVideos
} from '../../../features/user/userSlice';
import { getUserRecommendedVideoList } from '../../../features/user/userSlice';
import { selectAuthInfo } from '../../../features/auth/authSlice';

const UserRecommendedVideosContainer = ({
  recommendedListHeading,
  isSlider = false,
  limit = 12
}) => {
  const { isAuthorized } = useSelector(selectAuthInfo);
  const userRecommendedVideoList = useSelector(getUserRecommendedVideoList);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        isAuthorized && unwrapResult(await dispatch(fetchUserRecommendedVideos({ limit })));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [dispatch, isAuthorized, limit, i18n.language]);

  useEffect(() => {
    return () => {
      dispatch(resetRecommendedVideos());
    };
  }, [dispatch, i18n.language]);

  const updateVideoList = useCallback(async () => {
    return await unwrapResult(await dispatch(fetchUserRecommendedVideos({ limit })));
  }, [dispatch, limit]);

  return (
    isAuthorized &&
    (isSlider ? (
      <CardsSlider
        updateVideoList={updateVideoList}
        heading={recommendedListHeading}
        items={userRecommendedVideoList}
      />
    ) : (
      <RecommendedList
        updateVideoList={updateVideoList}
        items={userRecommendedVideoList}
        recommendedListHeading={recommendedListHeading}
      />
    ))
  );
};

UserRecommendedVideosContainer.propTypes = {
  recommendedListHeading: PropTypes.string,
  isSlider: PropTypes.bool,
  limit: PropTypes.number
};

export default UserRecommendedVideosContainer;
