import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  authLogin,
  authLoginCheck,
  authLoginOauth2,
  authPasswordCodeValidate,
  authPasswordResend,
  authPasswordReset,
  authRefreshToken,
  authRegister,
  authRegisterConfirm,
  authRegisterConfirmResend,
  authSmsCodeConfirm,
  authSmsCodeConfirmResend,
  authLogout,
  authCodeLogin
} from '../../api/authApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';
import { REFRESH_TOKEN_IS_INVALID } from '../../app/constants/configuration';
import { getQueryVariable } from '../../app/helpers/utils';

const setToken = (response = {}) => {
  const refresh_token = response.data && response.data.refresh_token;
  const access_token = response.data && response.data.access_token;

  if (refresh_token) {
    localStorage.setItem('refresh_token', refresh_token);
  }

  if (access_token) {
    localStorage.setItem('access_token', access_token);
  }
};

export const removeToken = () => {
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('access_token');
};

// Refresh token if access_token was expired
export const refreshToken = createAsyncThunk('auth/refreshToken', async (body) => {
  const response = await authRefreshToken(body);
  setToken(response);
  return response;
});

export const loginCheck = createAsyncThunk(
  'auth/loginCheck',
  async (body) => {
    return await authLoginCheck(body);
  }
);

// Login user
export const login = createAsyncThunk('auth/login', async (body) => {
  const response = await authLogin(body);
  setToken(response);
  return response;
});

// Logout user
export const logout = createAsyncThunk('auth/logout', async () => {
  // --- если removeToken() навернху, то токены с моими костылями удаляется из локал сторедж ---
  // removeToken();
  await authLogout();
  removeToken();
  return true;
});

// Register user
export const register = createAsyncThunk('auth/register', async (body) => {
  return await authRegister(body);
});

export const codeLogin = createAsyncThunk('auth/login/ws-code', async (body) => {
  await authCodeLogin(body);
  return true;
});

// Confirm with received code to submit successful registration
export const registerConfirm = createAsyncThunk('auth/registerConfirm', async (body) => {
  const response = await authRegisterConfirm(body);
  setToken(response);
  return response;
});

// If confirm code doesn't received, resend again
export const registerConfirmResend = createAsyncThunk(
  'auth/registerConfirmResend',
  async (body) => {
    return await authRegisterConfirmResend(body);
  }
);

// Confirm with received sms code to submit successful authorization
export const smsCodeConfirm = createAsyncThunk('auth/smsCodeConfirm', async (body) => {
  const response = await authSmsCodeConfirm(body);
  setToken(response);
  return response;
});

// If sms confirm code doesn't received, resend again
export const smsCodeConfirmResend = createAsyncThunk(
  'auth/smsCodeConfirmResend',
  async (body) => {
    return await authSmsCodeConfirmResend(body);
  }
);

// Receive confirm code at login for recovery password
export const passwordResend = createAsyncThunk('auth/passwordResend', async (body) => {
  return await authPasswordResend(body);
});

// Validate received confirm code
export const validatePasswordConfirmationCode = createAsyncThunk(
  'auth/validatePasswordConfirmationCode',
  async (body) => {
    return await authPasswordCodeValidate(body);
  }
);

// Change new password
export const passwordReset = createAsyncThunk('auth/passwordReset', async (body) => {
  const response = await authPasswordReset(body);
  setToken(response);
  return response;
});

// Login through social media
export const loginOauth2 = createAsyncThunk('auth/ouath2', async (body) => {
  const response = await authLoginOauth2(body);
  setToken(response);
  return response;
});

const initialState = {
  data: null,
  isAuthorized: false,
  statusIsAuthorized: false,
  isNotLogOuted: true,
  isLogOuted: false,
  isRefreshTokenInvalid: null,
  authorizedWithSocialMedia: false,
  authRequestAttempts: 0,
  loginLoadingStatus: 'idle',
  registerLoadingStatus: 'idle',
  error: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthorizedData: (state, action) => {
      state.data = action.payload;
    },
    // non-async reducers here
    setAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
      state.statusIsAuthorized = true;
      // state.isLogOuted = false;
    }
  },
  extraReducers: {
    [refreshToken.pending]: (state) => {
      state.loginLoadingStatus = FETCH_STATUS_LOADING;
      state.error = initialState.error;
      state.isRefreshTokenInvalid = false;
    },
    [refreshToken.fulfilled]: (state, action) => {
      state.loginLoadingStatus = FETCH_STATUS_SUCCESS;
      state.data = action.payload.data;
      state.isRefreshTokenInvalid = false;
    },
    [refreshToken.rejected]: (state, action) => {
      state.loginLoadingStatus = FETCH_STATUS_FAILED;
      if (action.error?.message === REFRESH_TOKEN_IS_INVALID) {
        state.isRefreshTokenInvalid = true;
        state.authRequestAttempts = state.authRequestAttempts + 1;
      }
      state.error = action.error;
    },
    [login.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [register.fulfilled]: (state) => {
      state.registerLoadingStatus = FETCH_STATUS_SUCCESS;
    },
    [registerConfirm.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [smsCodeConfirm.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [passwordReset.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [loginOauth2.pending]: (state) => {
      state.loginLoadingStatus = FETCH_STATUS_LOADING;
    },
    [loginOauth2.fulfilled]: (state, action) => {
      state.loginLoadingStatus = FETCH_STATUS_SUCCESS;
      state.data = action.payload.data;
      state.authorizedWithSocialMedia = true;
    },
    [loginOauth2.rejected]: (state) => {
      state.loginLoadingStatus = FETCH_STATUS_FAILED;
    },
    [logout.fulfilled]: (state) => {
      state.isAuthorized = false;
      state.isNotLogOuted = false; // when user did logout
      state.isLogOuted = true;
      state.data = null;
    }
  }
});

// Action creators
export const { setAuthorized, setAuthorizedData } = authSlice.actions;

// Selectors
export const selectAuthInfo = (state) => {
  return state.auth;
};
export const getLoginLoadingStatus = (state) => {
  return state.auth.loginLoadingStatus;
};
export const getAuthAccessToken = (state) => {
  if (window.location.search.length && window.location.search.includes('brizauth')) {
    const str = getQueryVariable('brizauth');
    const decodedQueryStr = atob(str);
    const jsonParsed = JSON.parse(decodedQueryStr);

    setToken({ data: jsonParsed });

    return jsonParsed.access_token;
  } else {
    return state.auth.data?.access_token;
  }
};

export const getAccessToken = (state) => {
  return state.auth.data?.access_token;
};

export default authSlice.reducer;
