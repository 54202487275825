import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Assets
import './NavBar.scss';
import { ReactComponent as MoreIcon } from '../../../../assets/images/svg/more.svg';
import classNames from 'classnames';
import { checkActivePath } from '../../../../helpers/utils';

const RecursiveRoutes = (item, index) => {
  const location = useLocation();
  return (
    (item.routes?.length && (
      <li key={index} className={item.class || null}>
        <Link to="#">
          <MoreIcon />
        </Link>
        <ul className="NavBar__dropdown">{item.routes.map(RecursiveRoutes)}</ul>
      </li>
    )) || (
      <li key={index} className={item.class || null}>
        <NavLink
          exact
          to={item.path}
          className={checkActivePath(item.activePathNames, location.pathname) ? 'active' : ''}
        >
          {item.title}{' '}
        </NavLink>
      </li>
    )
  );
};

const NavBar = ({ navBarRoutes, navBarClassName, isVisible }) => {
  return (
    isVisible && (
      <nav className={classNames('NavBar', navBarClassName)}>
        {!!navBarRoutes?.length && <ul>{navBarRoutes.map(RecursiveRoutes)}</ul>}
      </nav>
    )
  );
};

NavBar.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  navBarRoutes: PropTypes.array.isRequired,
  navBarClassName: PropTypes.string
};

export default React.memo(NavBar);
