import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';
import { mggFetchPremieresVideos } from '../../api/videoPremieresApi';

const initialState = {
  premieres: {
    status: 'idle',
    hasMore: false,
    limit: 30,
    offset: 0,
    total: 0,
    videoList: [],
    error: null
  }
};

export const fetchPremieresVideos = createAsyncThunk(
  'videoPremieres/fetchPremieresVideos',
  async ({ categoryId, limit, offset }) => {
    return await mggFetchPremieresVideos(categoryId, limit, offset);
  }
);

const videoPremieresSlice = createSlice({
  name: 'videoPremieres',
  initialState,
  reducers: {
    setInitialPremieresVideos: (state) => {
      state.premieres = initialState.premieres;
    },
    updatePremieresVideos: (state, action) => {
      const updatedPremiers = state.premieres.videoList.map((i) => {
        if (i.id === action.payload.id) {
          return action.payload;
        } else {
          return i;
        }
      });
      state.premieres.videoList = updatedPremiers;
    }
  },
  extraReducers: {
    [fetchPremieresVideos.pending]: (state) => {
      state.premieres.status = FETCH_STATUS_LOADING;
    },
    [fetchPremieresVideos.fulfilled]: (state, action) => {
      state.premieres.status = FETCH_STATUS_SUCCESS;
      state.premieres.videoList = action.payload.data.video_list;
      state.premieres.total = action.payload.data.total;
      state.premieres.limit = action.payload.data.limit;
      state.premieres.offset = action.payload.data.offset;
      state.premieres.hasMore = action.payload.data.has_more;
    },
    [fetchPremieresVideos.rejected]: (state, action) => {
      state.premieres.status = FETCH_STATUS_FAILED;
      state.premieres.error = action.error;
    }
  }
});

export const getPremieresVideosList = (state) => state.videoPremieres.premieres.videoList;
export const getPremieres = (state) => state.videoPremieres.premieres;

export const { setInitialPremieresVideos, updatePremieresVideos } = videoPremieresSlice.actions;

export default videoPremieresSlice.reducer;
