import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchStatusParentControl,
  setParentControl,
  unSetParentControl,
  valideatePinParentControl
} from '../../api/parentContrilApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  data: {},
  error: null,
  status: 'idle'
};

export const getStatusParentControl = createAsyncThunk('getStatusParentControl', async (body) => {
  return await fetchStatusParentControl(body);
});

export const set = createAsyncThunk('setParentControl', async (body) => {
  return await setParentControl(body);
});

export const unSet = createAsyncThunk('unSetParentControl', async (body) => {
  return await unSetParentControl(body);
});

export const update = createAsyncThunk('updateParentControl', async (body) => {
  return await setParentControl(body);
});

export const validate = createAsyncThunk('validateParentControl', async (body) => {
  return await valideatePinParentControl(body);
});

const parentControlSlice = createSlice({
  name: 'parentControl',
  initialState,
  extraReducers: {
    [getStatusParentControl.pending]: (state) => {
      state.status = FETCH_STATUS_LOADING;
    },
    [getStatusParentControl.fulfilled]: (state, action) => {
      state.status = FETCH_STATUS_SUCCESS;
      state.data = action.payload.data;
    },
    [getStatusParentControl.rejected]: (state, action) => {
      state.status = FETCH_STATUS_FAILED;
      state.error = action.error;
    },
    [set.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [unSet.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [unSet.rejected]: (state, action) => {
      state.status = FETCH_STATUS_FAILED;
      state.error = action.error;
    },
    [update.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [validate.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [validate.rejected]: (state, action) => {
      state.status = FETCH_STATUS_FAILED;
      state.error = action.error;
    }
  }
});

export const statusParentControl = (state) => state.parentControl.data;

export default parentControlSlice.reducer;
