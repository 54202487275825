import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './SliderButton.scss';

const SliderButton = ({ direction = 'next', disabled, clickHandler }) => {
  return (
    <button
      className="SliderButton"
      data-direction={direction}
      disabled={disabled}
      onClick={clickHandler}
    />
  );
};

SliderButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  direction: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default memo(SliderButton);
