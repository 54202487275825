import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchGetDevices, removeDevice } from '../../api/devicesApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  devices: {
    data: [],
    status: 'idle'
  }
};

export const fetchDevices = createAsyncThunk('devices', async (body) => {
  return await fetchGetDevices(body);
});

export const deleteDevice = createAsyncThunk('devices/deleteDivice', async (body) => {
  return await removeDevice(body);
});

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  extraReducers: {
    [fetchDevices.pending]: (state) => {
      state.devices.status = FETCH_STATUS_LOADING;
    },
    [fetchDevices.fulfilled]: (state, action) => {
      state.devices.status = FETCH_STATUS_SUCCESS;
      state.devices.data = action.payload.data;
    },
    [fetchDevices.rejected]: (state, action) => {
      state.devices.status = FETCH_STATUS_FAILED;
      state.devices.error = action.error;
    },
    [deleteDevice.fulfilled]: (state, action) => {
      state.devices.data = state.devices.data.filter(
        (item) => item.device_id !== action.meta.arg.device_id
      );
    }
  }
});

export const getAllDevices = (state) => state.devices.devices.data;

export default devicesSlice.reducer;
