import { IS_CHECK_PARENT_CONTROL } from '../constants/configuration';

export class HandleParentControlState {
  constructor() {
    if (this instanceof HandleParentControlState) {
      throw Error('A static class cannot be instantiated.');
    }
  }
  static getStateCheckedParentControl() {
    return sessionStorage.getItem(IS_CHECK_PARENT_CONTROL);
  }
  static setCheckedParentControl(value) {
    sessionStorage.setItem(IS_CHECK_PARENT_CONTROL, value);
  }
  static unsetCheckedParentControl() {
    sessionStorage.removeItem(IS_CHECK_PARENT_CONTROL);
  }
}
